/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ApproveStatusMatchCriteria,
  CityViewModel,
  CompetitionGroupCriteria,
  CompetitionGroupViewModel,
  CompetitionGroupViewModelIResult,
  CompetitionInviteCriteria,
  CompetitionMatchsOverviewViewModelIEnumerableIResult,
  CompetitionOptionsViewModelIResult,
  CompetitionTeamPlayerCriteria,
  CompetitionTeamPlayerSubscribeViewModelIQueryableIResult,
  CompetitionTeamPlayerSubscribeViewModelIResult,
  CompetitionViewModel,
  CompetitionViewModelIEnumerableIResult,
  CompetitionViewModelIResult,
  CountryViewModel,
  CreateCompetitionCriteria,
  CreateMatchCriteria,
  CreateTeamCriteria,
  ForgotPasswordCriteria,
  IActionResult,
  Int32StringKeyValuePairListResultBase,
  LoginCriteria,
  MatchCriteria,
  MatchOverviewViewModelIResult,
  MatchViewModelIResult,
  MatchViewModelListIResult,
  MatchViewModelListListIResult,
  OrganizationViewModel,
  PlayerViewModelICollectionIResult,
  PlayerViewModelIQueryableIResult,
  PlayerViewModelIResult,
  SetRefereeMatchCriteria,
  StateViewModel,
  StringListResultBase,
  TeamViewModel,
  TeamViewModelIEnumerableIResult,
  TeamViewModelIResult,
  UpdateCompetitionCriteria,
  UpdateMatchCriteria,
  UpdatePasswordCriteria,
  UpdatePlayerCompetitionRegisterCriteria,
  UpdateTeamCriteria,
  UpdateUserCriteria,
  UserViewModel,
  UserViewModelIResult,
  UserViewModelResultBase,
  V1CompetitiongroupsListParams,
  V1CompetitionsListParams,
  V1CompetitionsMatchsDetailParams,
  V1CompetitionsTeamsDetailParams,
  V1CompetitionsTeamsPlayerDetailParams,
  V1CompetitionsTeamsPlayersDetailParams,
  V1CompetitionsTeamsPlayersExportDetail2Params,
  V1CompetitionsTeamsPlayersExportDetailParams,
  V1GeolocationCitiesListParams,
  V1GeolocationCountriesListParams,
  V1GeolocationStatesListParams,
  V1MatchsGenerateDraftGroupDetailParams,
  V1OrganizationsListParams,
  V1PlayersListToInviteForCompetitionDetailParams,
  V1TeamsListParams,
  V1UserListParams,
  VerifyCodeCriteria,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags CompetitionGroups
   * @name V1CompetitiongroupsList
   * @request GET:/api/v1/competitiongroups
   * @secure
   * @response `200` `(CompetitionGroupViewModel)[]` Success
   */
  v1CompetitiongroupsList = (query: V1CompetitiongroupsListParams, params: RequestParams = {}) =>
    this.request<CompetitionGroupViewModel[], any>({
      path: `/api/v1/competitiongroups`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompetitionGroups
   * @name V1CompetitiongroupsCreate
   * @request POST:/api/v1/competitiongroups
   * @secure
   * @response `200` `CompetitionGroupViewModelIResult` Success
   */
  v1CompetitiongroupsCreate = (data: CompetitionGroupCriteria, params: RequestParams = {}) =>
    this.request<CompetitionGroupViewModelIResult, any>({
      path: `/api/v1/competitiongroups`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompetitionGroups
   * @name V1CompetitiongroupsUpdate
   * @request PUT:/api/v1/competitiongroups
   * @secure
   * @response `200` `CompetitionGroupViewModelIResult` Success
   */
  v1CompetitiongroupsUpdate = (data: CompetitionGroupCriteria, params: RequestParams = {}) =>
    this.request<CompetitionGroupViewModelIResult, any>({
      path: `/api/v1/competitiongroups`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompetitionGroups
   * @name V1CompetitiongroupsDetail
   * @request GET:/api/v1/competitiongroups/{id}
   * @secure
   * @response `200` `CompetitionGroupViewModelIResult` Success
   */
  v1CompetitiongroupsDetail = (id: number, params: RequestParams = {}) =>
    this.request<CompetitionGroupViewModelIResult, any>({
      path: `/api/v1/competitiongroups/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsList
   * @request GET:/api/v1/competitions
   * @secure
   * @response `200` `(CompetitionViewModel)[]` Success
   */
  v1CompetitionsList = (query: V1CompetitionsListParams, params: RequestParams = {}) =>
    this.request<CompetitionViewModel[], any>({
      path: `/api/v1/competitions`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsUpdate
   * @request PUT:/api/v1/competitions
   * @secure
   * @response `200` `CompetitionViewModelIResult` Success
   */
  v1CompetitionsUpdate = (data: UpdateCompetitionCriteria, params: RequestParams = {}) =>
    this.request<CompetitionViewModelIResult, any>({
      path: `/api/v1/competitions`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsCreate
   * @request POST:/api/v1/competitions
   * @secure
   * @response `200` `CompetitionViewModelIResult` Success
   */
  v1CompetitionsCreate = (data: CreateCompetitionCriteria, params: RequestParams = {}) =>
    this.request<CompetitionViewModelIResult, any>({
      path: `/api/v1/competitions`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsDetail
   * @request GET:/api/v1/competitions/{id}
   * @secure
   * @response `200` `CompetitionViewModelIResult` Success
   */
  v1CompetitionsDetail = (id: number, params: RequestParams = {}) =>
    this.request<CompetitionViewModelIResult, any>({
      path: `/api/v1/competitions/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsTeamsGetCompetitionDetail
   * @request GET:/api/v1/competitions/teams/{teamId}/get-competition
   * @secure
   * @response `200` `CompetitionViewModelIResult` Success
   */
  v1CompetitionsTeamsGetCompetitionDetail = (teamId: number, params: RequestParams = {}) =>
    this.request<CompetitionViewModelIResult, any>({
      path: `/api/v1/competitions/teams/${teamId}/get-competition`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsTeamsDetail
   * @request GET:/api/v1/competitions/{id}/teams
   * @secure
   * @response `200` `TeamViewModelIEnumerableIResult` Success
   */
  v1CompetitionsTeamsDetail = ({ id, ...query }: V1CompetitionsTeamsDetailParams, params: RequestParams = {}) =>
    this.request<TeamViewModelIEnumerableIResult, any>({
      path: `/api/v1/competitions/${id}/teams`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsTeamsPlayersDetail
   * @request GET:/api/v1/competitions/{id}/teams/{teamId}/players
   * @secure
   * @response `200` `CompetitionTeamPlayerSubscribeViewModelIQueryableIResult` Success
   */
  v1CompetitionsTeamsPlayersDetail = (
    { teamId, id, ...query }: V1CompetitionsTeamsPlayersDetailParams,
    params: RequestParams = {},
  ) =>
    this.request<CompetitionTeamPlayerSubscribeViewModelIQueryableIResult, any>({
      path: `/api/v1/competitions/${id}/teams/${teamId}/players`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsTeamsPlayerDetail
   * @request GET:/api/v1/competitions/{id}/teams/{teamId}/player
   * @secure
   * @response `200` `CompetitionTeamPlayerSubscribeViewModelIResult` Success
   */
  v1CompetitionsTeamsPlayerDetail = (
    { id, teamId, ...query }: V1CompetitionsTeamsPlayerDetailParams,
    params: RequestParams = {},
  ) =>
    this.request<CompetitionTeamPlayerSubscribeViewModelIResult, any>({
      path: `/api/v1/competitions/${id}/teams/${teamId}/player`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsTeamsPlayersExportDetail
   * @request GET:/api/v1/competitions/{id}/teams/{teamId}/players/export
   * @secure
   * @response `200` `IActionResult` Success
   */
  v1CompetitionsTeamsPlayersExportDetail = (
    { id, teamId, ...query }: V1CompetitionsTeamsPlayersExportDetailParams,
    params: RequestParams = {},
  ) =>
    this.request<IActionResult, any>({
      path: `/api/v1/competitions/${id}/teams/${teamId}/players/export`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsTeamsPlayersExportDetail2
   * @request GET:/api/v1/competitions/{id}/teams/players/export
   * @originalName v1CompetitionsTeamsPlayersExportDetail
   * @duplicate
   * @secure
   * @response `200` `IActionResult` Success
   */
  v1CompetitionsTeamsPlayersExportDetail2 = (
    { id, ...query }: V1CompetitionsTeamsPlayersExportDetail2Params,
    params: RequestParams = {},
  ) =>
    this.request<IActionResult, any>({
      path: `/api/v1/competitions/${id}/teams/players/export`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsTeamsAddCreate
   * @request POST:/api/v1/competitions/{id}/teams/{teamId}/add
   * @secure
   * @response `200` `TeamViewModelIResult` Success
   */
  v1CompetitionsTeamsAddCreate = (id: number, teamId: number, params: RequestParams = {}) =>
    this.request<TeamViewModelIResult, any>({
      path: `/api/v1/competitions/${id}/teams/${teamId}/add`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsTeamsDelete
   * @request DELETE:/api/v1/competitions/{id}/teams/{teamId}
   * @secure
   * @response `200` `TeamViewModelIResult` Success
   */
  v1CompetitionsTeamsDelete = (id: number, teamId: number, params: RequestParams = {}) =>
    this.request<TeamViewModelIResult, any>({
      path: `/api/v1/competitions/${id}/teams/${teamId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsMatchsDetail
   * @request GET:/api/v1/competitions/{id}/matchs
   * @secure
   * @response `200` `CompetitionMatchsOverviewViewModelIEnumerableIResult` Success
   */
  v1CompetitionsMatchsDetail = ({ id, ...query }: V1CompetitionsMatchsDetailParams, params: RequestParams = {}) =>
    this.request<CompetitionMatchsOverviewViewModelIEnumerableIResult, any>({
      path: `/api/v1/competitions/${id}/matchs`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsOpitionsDetail
   * @request GET:/api/v1/competitions/opitions/{type}
   * @secure
   * @response `200` `CompetitionOptionsViewModelIResult` Success
   */
  v1CompetitionsOpitionsDetail = (type: string, params: RequestParams = {}) =>
    this.request<CompetitionOptionsViewModelIResult, any>({
      path: `/api/v1/competitions/opitions/${type}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsTypesList
   * @request GET:/api/v1/competitions/types
   * @secure
   * @response `200` `StringListResultBase` Success
   */
  v1CompetitionsTypesList = (params: RequestParams = {}) =>
    this.request<StringListResultBase, any>({
      path: `/api/v1/competitions/types`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsModalitiesList
   * @request GET:/api/v1/competitions/modalities
   * @secure
   * @response `200` `Int32StringKeyValuePairListResultBase` Success
   */
  v1CompetitionsModalitiesList = (params: RequestParams = {}) =>
    this.request<Int32StringKeyValuePairListResultBase, any>({
      path: `/api/v1/competitions/modalities`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsSetEnrollPeriodUpdate
   * @request PUT:/api/v1/competitions/{id}/set-enroll-period/{enrollPeriod}
   * @secure
   * @response `200` `CompetitionViewModelIResult` Success
   */
  v1CompetitionsSetEnrollPeriodUpdate = (id: number, enrollPeriod: string, params: RequestParams = {}) =>
    this.request<CompetitionViewModelIResult, any>({
      path: `/api/v1/competitions/${id}/set-enroll-period/${enrollPeriod}`,
      method: "PUT",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsFinishUpdate
   * @request PUT:/api/v1/competitions/{id}/finish
   * @secure
   * @response `200` `CompetitionViewModelIResult` Success
   */
  v1CompetitionsFinishUpdate = (id: number, params: RequestParams = {}) =>
    this.request<CompetitionViewModelIResult, any>({
      path: `/api/v1/competitions/${id}/finish`,
      method: "PUT",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsCancelDelete
   * @request DELETE:/api/v1/competitions/{id}/cancel
   * @secure
   * @response `200` `CompetitionViewModelIResult` Success
   */
  v1CompetitionsCancelDelete = (id: number, params: RequestParams = {}) =>
    this.request<CompetitionViewModelIResult, any>({
      path: `/api/v1/competitions/${id}/cancel`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsDeleteAllMatchesDelete
   * @request DELETE:/api/v1/competitions/{id}/delete-all-matches
   * @secure
   * @response `200` `CompetitionViewModelIEnumerableIResult` Success
   */
  v1CompetitionsDeleteAllMatchesDelete = (id: number, params: RequestParams = {}) =>
    this.request<CompetitionViewModelIEnumerableIResult, any>({
      path: `/api/v1/competitions/${id}/delete-all-matches`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsTeamsPlayersToSubscribeDetail
   * @request GET:/api/v1/competitions/{id}/teams/{teamId}/players-to-subscribe
   * @secure
   * @response `200` `PlayerViewModelIQueryableIResult` Success
   */
  v1CompetitionsTeamsPlayersToSubscribeDetail = (id: number, teamId: number, params: RequestParams = {}) =>
    this.request<PlayerViewModelIQueryableIResult, any>({
      path: `/api/v1/competitions/${id}/teams/${teamId}/players-to-subscribe`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Competitions
   * @name V1CompetitionsInvitePlayerCreate
   * @request POST:/api/v1/competitions/{id}/invite-player
   * @secure
   * @response `200` `PlayerViewModelIQueryableIResult` Success
   */
  v1CompetitionsInvitePlayerCreate = (id: number, data: CompetitionInviteCriteria, params: RequestParams = {}) =>
    this.request<PlayerViewModelIQueryableIResult, any>({
      path: `/api/v1/competitions/${id}/invite-player`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GeoLocation
   * @name V1GeolocationCountriesList
   * @request GET:/api/v1/geolocation/countries
   * @secure
   * @response `200` `(CountryViewModel)[]` Success
   */
  v1GeolocationCountriesList = (query: V1GeolocationCountriesListParams, params: RequestParams = {}) =>
    this.request<CountryViewModel[], any>({
      path: `/api/v1/geolocation/countries`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GeoLocation
   * @name V1GeolocationStatesList
   * @request GET:/api/v1/geolocation/states
   * @secure
   * @response `200` `(StateViewModel)[]` Success
   */
  v1GeolocationStatesList = (query: V1GeolocationStatesListParams, params: RequestParams = {}) =>
    this.request<StateViewModel[], any>({
      path: `/api/v1/geolocation/states`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GeoLocation
   * @name V1GeolocationCitiesList
   * @request GET:/api/v1/geolocation/cities
   * @secure
   * @response `200` `(CityViewModel)[]` Success
   */
  v1GeolocationCitiesList = (query: V1GeolocationCitiesListParams, params: RequestParams = {}) =>
    this.request<CityViewModel[], any>({
      path: `/api/v1/geolocation/cities`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Login
   * @name V1LoginCreate
   * @request POST:/api/v1/login
   * @secure
   * @response `200` `UserViewModelResultBase` Success
   */
  v1LoginCreate = (data: LoginCriteria, params: RequestParams = {}) =>
    this.request<UserViewModelResultBase, any>({
      path: `/api/v1/login`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Login
   * @name V1LoginForgotPasswordCreate
   * @request POST:/api/v1/login/forgot-password
   * @secure
   * @response `200` `UserViewModelResultBase` Success
   */
  v1LoginForgotPasswordCreate = (data: ForgotPasswordCriteria, params: RequestParams = {}) =>
    this.request<UserViewModelResultBase, any>({
      path: `/api/v1/login/forgot-password`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Login
   * @name V1LoginResendcodeCreate
   * @request POST:/api/v1/login/resendcode/{loginId}
   * @secure
   * @response `200` `UserViewModelResultBase` Success
   */
  v1LoginResendcodeCreate = (loginId: number, params: RequestParams = {}) =>
    this.request<UserViewModelResultBase, any>({
      path: `/api/v1/login/resendcode/${loginId}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Login
   * @name V1LoginVerifycodeCreate
   * @request POST:/api/v1/login/verifycode
   * @secure
   * @response `200` `UserViewModelResultBase` Success
   */
  v1LoginVerifycodeCreate = (data: VerifyCodeCriteria, params: RequestParams = {}) =>
    this.request<UserViewModelResultBase, any>({
      path: `/api/v1/login/verifycode`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Login
   * @name V1LoginUpdatepasswordCreate
   * @request POST:/api/v1/login/updatepassword
   * @secure
   * @response `200` `UserViewModelIResult` Success
   */
  v1LoginUpdatepasswordCreate = (data: UpdatePasswordCriteria, params: RequestParams = {}) =>
    this.request<UserViewModelIResult, any>({
      path: `/api/v1/login/updatepassword`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsOverviewDetail
   * @request GET:/api/v1/matchs/{id}/overview
   * @secure
   * @response `200` `MatchOverviewViewModelIResult` Success
   */
  v1MatchsOverviewDetail = (id: number, params: RequestParams = {}) =>
    this.request<MatchOverviewViewModelIResult, any>({
      path: `/api/v1/matchs/${id}/overview`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsCreate
   * @request POST:/api/v1/matchs
   * @secure
   * @response `200` `MatchViewModelIResult` Success
   */
  v1MatchsCreate = (data: CreateMatchCriteria, params: RequestParams = {}) =>
    this.request<MatchViewModelIResult, any>({
      path: `/api/v1/matchs`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsUpdate
   * @request PUT:/api/v1/matchs
   * @secure
   * @response `200` `MatchViewModelIResult` Success
   */
  v1MatchsUpdate = (data: UpdateMatchCriteria, params: RequestParams = {}) =>
    this.request<MatchViewModelIResult, any>({
      path: `/api/v1/matchs`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsInvertCreate
   * @request POST:/api/v1/matchs/{id}/invert
   * @secure
   * @response `200` `MatchViewModelIResult` Success
   */
  v1MatchsInvertCreate = (id: number, params: RequestParams = {}) =>
    this.request<MatchViewModelIResult, any>({
      path: `/api/v1/matchs/${id}/invert`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsSaveDraftCreate
   * @request POST:/api/v1/matchs/save-draft
   * @secure
   * @response `200` `MatchViewModelIResult` Success
   */
  v1MatchsSaveDraftCreate = (data: CreateMatchCriteria[], params: RequestParams = {}) =>
    this.request<MatchViewModelIResult, any>({
      path: `/api/v1/matchs/save-draft`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsSaveDraftGroupsCreate
   * @request POST:/api/v1/matchs/save-draft-groups
   * @secure
   * @response `200` `MatchViewModelIResult` Success
   */
  v1MatchsSaveDraftGroupsCreate = (data: CreateMatchCriteria[][], params: RequestParams = {}) =>
    this.request<MatchViewModelIResult, any>({
      path: `/api/v1/matchs/save-draft-groups`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsGenerateDraftDetail
   * @request GET:/api/v1/matchs/generate-draft/{competitionId}
   * @secure
   * @response `200` `MatchViewModelListIResult` Success
   */
  v1MatchsGenerateDraftDetail = (competitionId: number, params: RequestParams = {}) =>
    this.request<MatchViewModelListIResult, any>({
      path: `/api/v1/matchs/generate-draft/${competitionId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsGenerateDraftGroupDetail
   * @request GET:/api/v1/matchs/generate-draft-group/{competitionId}
   * @secure
   * @response `200` `MatchViewModelListIResult` Success
   */
  v1MatchsGenerateDraftGroupDetail = (
    { competitionId, ...query }: V1MatchsGenerateDraftGroupDetailParams,
    params: RequestParams = {},
  ) =>
    this.request<MatchViewModelListIResult, any>({
      path: `/api/v1/matchs/generate-draft-group/${competitionId}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsGenerateRandonDraftGroupsDetail
   * @request GET:/api/v1/matchs/generate-randon-draft-groups/{competitionId}
   * @secure
   * @response `200` `MatchViewModelListListIResult` Success
   */
  v1MatchsGenerateRandonDraftGroupsDetail = (competitionId: number, params: RequestParams = {}) =>
    this.request<MatchViewModelListListIResult, any>({
      path: `/api/v1/matchs/generate-randon-draft-groups/${competitionId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsPhasesList
   * @request GET:/api/v1/matchs/phases
   * @secure
   * @response `200` `StringListResultBase` Success
   */
  v1MatchsPhasesList = (params: RequestParams = {}) =>
    this.request<StringListResultBase, any>({
      path: `/api/v1/matchs/phases`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsGroupsDetail
   * @request GET:/api/v1/matchs/groups/{idCompetition}
   * @secure
   * @response `200` `StringListResultBase` Success
   */
  v1MatchsGroupsDetail = (idCompetition: number, params: RequestParams = {}) =>
    this.request<StringListResultBase, any>({
      path: `/api/v1/matchs/groups/${idCompetition}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsRoundsDetail
   * @request GET:/api/v1/matchs/rounds/{idCompetition}
   * @secure
   * @response `200` `StringListResultBase` Success
   */
  v1MatchsRoundsDetail = (idCompetition: number, params: RequestParams = {}) =>
    this.request<StringListResultBase, any>({
      path: `/api/v1/matchs/rounds/${idCompetition}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsApproveUpdate
   * @request PUT:/api/v1/matchs/approve
   * @secure
   * @response `200` `MatchViewModelIResult` Success
   */
  v1MatchsApproveUpdate = (data: ApproveStatusMatchCriteria, params: RequestParams = {}) =>
    this.request<MatchViewModelIResult, any>({
      path: `/api/v1/matchs/approve`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsRestoreUpdate
   * @request PUT:/api/v1/matchs/{id}/restore
   * @secure
   * @response `200` `MatchViewModelIResult` Success
   */
  v1MatchsRestoreUpdate = (id: number, params: RequestParams = {}) =>
    this.request<MatchViewModelIResult, any>({
      path: `/api/v1/matchs/${id}/restore`,
      method: "PUT",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsSetPlaceAndDatetimeUpdate
   * @request PUT:/api/v1/matchs/set-place-and-datetime
   * @secure
   * @response `200` `MatchViewModelIResult` Success
   */
  v1MatchsSetPlaceAndDatetimeUpdate = (data: MatchCriteria, params: RequestParams = {}) =>
    this.request<MatchViewModelIResult, any>({
      path: `/api/v1/matchs/set-place-and-datetime`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsSetRefereeUpdate
   * @request PUT:/api/v1/matchs/set-referee
   * @secure
   * @response `200` `MatchViewModelIResult` Success
   */
  v1MatchsSetRefereeUpdate = (data: SetRefereeMatchCriteria, params: RequestParams = {}) =>
    this.request<MatchViewModelIResult, any>({
      path: `/api/v1/matchs/set-referee`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsCancelUpdate
   * @request PUT:/api/v1/matchs/{id}/cancel
   * @secure
   * @response `200` `MatchViewModelIResult` Success
   */
  v1MatchsCancelUpdate = (id: number, params: RequestParams = {}) =>
    this.request<MatchViewModelIResult, any>({
      path: `/api/v1/matchs/${id}/cancel`,
      method: "PUT",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Matchs
   * @name V1MatchsDelete
   * @request DELETE:/api/v1/matchs/{id}
   * @secure
   * @response `200` `MatchViewModelIResult` Success
   */
  v1MatchsDelete = (id: number, params: RequestParams = {}) =>
    this.request<MatchViewModelIResult, any>({
      path: `/api/v1/matchs/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organizations
   * @name V1OrganizationsList
   * @request GET:/api/v1/organizations
   * @secure
   * @response `200` `(OrganizationViewModel)[]` Success
   */
  v1OrganizationsList = (query: V1OrganizationsListParams, params: RequestParams = {}) =>
    this.request<OrganizationViewModel[], any>({
      path: `/api/v1/organizations`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Players
   * @name V1PlayersDetail
   * @request GET:/api/v1/players/{id}
   * @secure
   * @response `200` `PlayerViewModelIResult` Success
   */
  v1PlayersDetail = (id: number, params: RequestParams = {}) =>
    this.request<PlayerViewModelIResult, any>({
      path: `/api/v1/players/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Players
   * @name V1PlayersRegisterForCompetitionCreate
   * @request POST:/api/v1/players/register-for-competition
   * @secure
   * @response `200` `CompetitionTeamPlayerSubscribeViewModelIResult` Success
   */
  v1PlayersRegisterForCompetitionCreate = (data: CompetitionTeamPlayerCriteria, params: RequestParams = {}) =>
    this.request<CompetitionTeamPlayerSubscribeViewModelIResult, any>({
      path: `/api/v1/players/register-for-competition`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Players
   * @name V1PlayersUpdateCompetitionRegistrationUpdate
   * @request PUT:/api/v1/players/update-competition-registration
   * @secure
   * @response `200` `CompetitionTeamPlayerSubscribeViewModelIResult` Success
   */
  v1PlayersUpdateCompetitionRegistrationUpdate = (
    data: UpdatePlayerCompetitionRegisterCriteria,
    params: RequestParams = {},
  ) =>
    this.request<CompetitionTeamPlayerSubscribeViewModelIResult, any>({
      path: `/api/v1/players/update-competition-registration`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Players
   * @name V1PlayersRemoveFromCompetitionDelete
   * @request DELETE:/api/v1/players/{id}/remove-from-competition/{competitionId}
   * @secure
   * @response `200` `CompetitionTeamPlayerSubscribeViewModelIResult` Success
   */
  v1PlayersRemoveFromCompetitionDelete = (id: number, competitionId: number, params: RequestParams = {}) =>
    this.request<CompetitionTeamPlayerSubscribeViewModelIResult, any>({
      path: `/api/v1/players/${id}/remove-from-competition/${competitionId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Players
   * @name V1PlayersEditRegisterInCompetitionCreate
   * @request POST:/api/v1/players/edit-register-in-competition
   * @secure
   * @response `200` `CompetitionTeamPlayerSubscribeViewModelIResult` Success
   */
  v1PlayersEditRegisterInCompetitionCreate = (data: CompetitionTeamPlayerCriteria, params: RequestParams = {}) =>
    this.request<CompetitionTeamPlayerSubscribeViewModelIResult, any>({
      path: `/api/v1/players/edit-register-in-competition`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Players
   * @name V1PlayersRemoveFromPreRegistrationDelete
   * @request DELETE:/api/v1/players/{id}/remove-from-pre-registration/{competitionId}
   * @secure
   * @response `200` `CompetitionTeamPlayerSubscribeViewModelIResult` Success
   */
  v1PlayersRemoveFromPreRegistrationDelete = (id: number, competitionId: number, params: RequestParams = {}) =>
    this.request<CompetitionTeamPlayerSubscribeViewModelIResult, any>({
      path: `/api/v1/players/${id}/remove-from-pre-registration/${competitionId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Players
   * @name V1PlayersListToInviteForCompetitionDetail
   * @request GET:/api/v1/players/list-to-invite-for-competition/{competitionId}
   * @secure
   * @response `200` `PlayerViewModelICollectionIResult` Success
   */
  v1PlayersListToInviteForCompetitionDetail = (
    { competitionId, ...query }: V1PlayersListToInviteForCompetitionDetailParams,
    params: RequestParams = {},
  ) =>
    this.request<PlayerViewModelICollectionIResult, any>({
      path: `/api/v1/players/list-to-invite-for-competition/${competitionId}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Teams
   * @name V1TeamsList
   * @request GET:/api/v1/teams
   * @secure
   * @response `200` `(TeamViewModel)[]` Success
   */
  v1TeamsList = (query: V1TeamsListParams, params: RequestParams = {}) =>
    this.request<TeamViewModel[], any>({
      path: `/api/v1/teams`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Teams
   * @name V1TeamsCreate
   * @request POST:/api/v1/teams
   * @secure
   * @response `200` `TeamViewModelIResult` Success
   */
  v1TeamsCreate = (data: CreateTeamCriteria, params: RequestParams = {}) =>
    this.request<TeamViewModelIResult, any>({
      path: `/api/v1/teams`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Teams
   * @name V1TeamsUpdate
   * @request PUT:/api/v1/teams
   * @secure
   * @response `200` `TeamViewModelIResult` Success
   */
  v1TeamsUpdate = (data: UpdateTeamCriteria, params: RequestParams = {}) =>
    this.request<TeamViewModelIResult, any>({
      path: `/api/v1/teams`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Teams
   * @name V1TeamsDetail
   * @request GET:/api/v1/teams/{id}
   * @secure
   * @response `200` `TeamViewModelIResult` Success
   */
  v1TeamsDetail = (id: number, params: RequestParams = {}) =>
    this.request<TeamViewModelIResult, any>({
      path: `/api/v1/teams/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name V1UserList
   * @request GET:/api/v1/user
   * @secure
   * @response `200` `(UserViewModel)[]` Success
   */
  v1UserList = (query: V1UserListParams, params: RequestParams = {}) =>
    this.request<UserViewModel[], any>({
      path: `/api/v1/user`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name V1UserCheckRegistrationStatusCreate
   * @request POST:/api/v1/user/check-registration-status/{loginId}
   * @secure
   * @response `200` `UserViewModelIResult` Success
   */
  v1UserCheckRegistrationStatusCreate = (loginId: number, params: RequestParams = {}) =>
    this.request<UserViewModelIResult, any>({
      path: `/api/v1/user/check-registration-status/${loginId}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name V1UserUpdateBasicInfosUpdate
   * @request PUT:/api/v1/user/update-basic-infos
   * @secure
   * @response `200` `UserViewModelIResult` Success
   */
  v1UserUpdateBasicInfosUpdate = (data: UpdateUserCriteria, params: RequestParams = {}) =>
    this.request<UserViewModelIResult, any>({
      path: `/api/v1/user/update-basic-infos`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
