import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/authentication';
import Login from '../pages/authentication/Login';
import { useAuthAction } from '../redux/actions/authAction';
//import { useUserAction } from '../redux/actions/userAction';
import { useSelector } from 'react-redux';
import { AuthReducerState } from '../redux/reducers/authReducer';
import miscellaneousReducer from '../redux/reducers/miscellaneousReducer';
import { useMiscellaneousAction } from '../redux/actions/miscellaneousAction';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];


const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...initialState,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const { miscellaneousLogoutAction } = useMiscellaneousAction();

  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const {logoutAction} = useAuthAction()
  //const {clearUserData} = useUserAction()
  const { token, login } = useSelector((state: { auth: AuthReducerState }) => state?.auth);
  useEffect(() => {
    const initialize = async () => {
      try {
        if (token && isValidToken(token)) {
          setSession(token);

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: login ?? ""
            }
          });
        } else {
          await logout()
          //clearUserData()
          dispatch({
            type: Types.Logout,
          });
          return <Login/>;
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const loginAccount = async (email: string, password: string) => {
    const response = await axios.post('/api/account/login', {
      email,
      password
    });
    const { accessToken, user } = response.data;

    setSession(accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
  };
  
  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
    miscellaneousLogoutAction();
    logoutAction()
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        loginAccount,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
