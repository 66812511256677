import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';

// material
import { Stack, Alert, Link, MenuItem, Grid, InputAdornment, TextField, Typography, IconButton, } from '@mui/material';

// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// components
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// services
import { useAuthService } from '../../../services/AuthService';

import { PATH_AUTH, PATH_MANAGER } from '../../../routes/paths';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ThemeReducerState } from '../../../redux/reducers/themeReducer';
import { LanguageReducerState } from '../../../redux/reducers/languageReducer';
import { useEffect, useState } from 'react';
import { getKeyByValue } from '../../../utils/enums';
import { MButtonOnlyIcon } from '../../../components/@material-extend';
import { TextInput } from '../../../components/TextInput';
import {setGlobalLanguage} from'../../../locales/i18n';
import { AccessRoleType } from '../../../apis/data-contracts';
import { AuthReducerState } from '../../../redux/reducers/authReducer';


type LoginFormValues = {
  login: string;
  password: string;
  afterSubmit?: string;
};
type LoginFormProps = {
  setLoading: (loading: boolean) => void;
  initialLogin: string;
};

export function LoginForm({setLoading, initialLogin}: LoginFormProps) {
  const navigate = useNavigate();
  const { login } = useAuthService();
  const isMountedRef = useIsMountedRef();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<string>(i18n.language);
  const [didMount, setDidMount] = useState<boolean>(false);
  const { roles } = useSelector((state: { auth: AuthReducerState }) => state?.auth);

  
  const handleChangeLanguage = (lng: string) => {
    setGlobalLanguage(lng as LanguageReducerState);
  };

  const LoginSchema = Yup.object().shape({
    login: Yup.string().email(t('competitionManager:invalidEmail')).required(t('competitionManager:emailRequired')),
    password: Yup.string().required(t('competitionManager:passwordRequired'))
  });

  const formik = useFormik<LoginFormValues>({
    initialValues: {
      login: initialLogin,
      password: '',

    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setLoading(true);
      await login(values.login, values.password)
        .then((data) => {
          if(data.isRegistrationOk) {
            if (!roles?.includes(AccessRoleType.SysAdmin) && !roles?.includes(AccessRoleType.CompetitionManager) && roles?.includes(AccessRoleType.TeamManager)
            || (!data.roles?.includes(AccessRoleType.SysAdmin) && !data.roles?.includes(AccessRoleType.CompetitionManager) && data.roles?.includes(AccessRoleType.TeamManager)))
            {
              navigate(PATH_MANAGER.competition.team.list);
            }
            else{
              navigate(PATH_MANAGER.competitionGroups.root);
            }
          }
          else{
            navigate(PATH_MANAGER.settings.user);
          }        
        }).catch(async (authData) => {         
            if (isMountedRef.current) {
              setErrors({ afterSubmit: t('competitionManager:InvalidLogin') });
            }

            setSubmitting(false);
            setLoading(false);
        })
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, handleChange, getFieldProps, values } = formik;
  const { selectedTheme } = useSelector((state: { theme: ThemeReducerState }) => state.theme)


  // const redirectToVerifyToken = () => {
  //   navigate(PATH_AUTH.resetPassword, {
  //     state: {
  //       login: 'df',
  //     }
  //   });
  // };
  
  useEffect(() => {
    if(!didMount) return;
    handleChangeLanguage(language);
  }, [language]);

  useEffect(() => {
    if(!didMount) setDidMount(true);
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity='error'>{errors.afterSubmit}</Alert>}
          <TextInput
            {...getFieldProps('login')}
            fullWidth
            sx={selectedTheme === 'dark' ? {
              input: {
                '&:-webkit-autofill': {
                  WebkitBoxShadow: '0 0 0 100px #161C24 inset',
                  WebkitTextFillColor: '#fff',
                },
              },
              backgroundColor:'transparent',
              '& label.Mui-focused': {
                color: 'white',
              },
              '& .MuiOutlinedInput-root': {

                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },
            } : undefined}
            //autoComplete='email'
            autoComplete='off'
            mask='username'
            type='email'
            label={t(`competitionManager:emailLabel`)}
            onChange={handleChange('login')}
            error={Boolean(touched.login && errors.login)}
            helperText={touched.login && errors.login}
          />
          <TextInput
            {...getFieldProps('password')}
            fullWidth
            sx={selectedTheme === 'dark' ? {
              input: {
                '&:-webkit-autofill': {
                  WebkitBoxShadow: '0 0 0 100px #161C24 inset',
                  WebkitTextFillColor: '#fff',
                },
              },
              backgroundColor:'transparent',
              '& label.Mui-focused': {
                color: 'white',
              },
              '& .MuiOutlinedInput-root': {

                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },
            } : undefined}
            secureTextEntry
            //autoComplete='current-password'
            autoComplete='off'
            type='password'
            label={t(`competitionManager:passwordLabel`)}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          
          <Grid item xs={8} sm={8} md={8} lg={8} xl={6} mt={3}>
            <TextField
            sx={selectedTheme === 'dark' ? {
              '& label.Mui-focused': {
                color: 'white',
              },
              '& .MuiOutlinedInput-root': {
                
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },
            } : undefined}
            //label={t('competitionManager:formFields:status')}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                <Icon icon={'mdi:world'} width={35} height={35}/>
                </InputAdornment>
              )
            }}
            value={language}
            fullWidth
            onChange={(e) => setLanguage((Object.keys(LanguageReducerState).includes(e.target.value as LanguageReducerState) ? e.target.value : language))}
            select
            >
              {Object.values(LanguageReducerState).map((option: string) => {
                let optionLanguage = getKeyByValue(option, LanguageReducerState);
                return (
                  <MenuItem key={optionLanguage} value={optionLanguage}>
                    {option}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3}>
            <Stack alignItems={'flex-end'} spacing={3} direction='row' justifyContent={'space-between'}>
              <Grid></Grid>
              {/* <Typography variant='h4' gutterBottom>
                    {t('competitionManager:signIncompetitionManager:signIn')}
              </Typography> */}
              <MButtonOnlyIcon icon={'mdi:arrow-right-thick'} onClick={() => {handleSubmit()}}></MButtonOnlyIcon>
            </Stack>
          </Grid>
        </Stack>
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ my: 2 }}>
          {/* <Link color={'GrayText'} component={RouterLink} variant='subtitle2' to={PATH_AUTH.register} state={{login: values.login}}>
            {t('competitionManager:isItYourFirstAccess')}
          </Link> */}
          <Link color={'GrayText'} component={RouterLink} variant='subtitle2' to={PATH_AUTH.verifyEmail} state={{login: values.login}}>
            {t('competitionManager:forgotPassword')}
          </Link>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
