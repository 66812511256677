{
  "title": "English",
  "inputInitialDate": "Initial Date",
  "inputFinalDate": "Final Date",
  "signIncompetitionManager": {
    "signIn": "Sign In",
    "welcome": "Welcome",
    "signInDetails": "Confirm the information below."
  },
  "startDateCantBeGreaterThanFinalDate": "The start date cannot be greater than the final date!",
  "LoginSuccess": "Login Success",
  "Login": "Login",
  "InvalidLogin": "Invalid login or password.",
  "search": "Search",
  "logout": "Logout",
  "logoutUnable": "Unable to logout!",
  "emailRequired": "Email is required!.",
  "passwordRequired": "Password is required!",
  "emailLabel": "Email address",
  "passwordLabel": "Password",
  "titleRequired": "Title is required!",
  "dateRequired": "Date is required!",
  "fieldRequired": "The field is required!",
  "championshipManager": "Championship manager",
  "accountSettings": "Account Settings",
  "settings": "Settings",
  "forgotPassword": "Forgot password?",
  "resetPassword": "Reset password",
  "back": "Go back",
  "msgEnterEmailToResetPassword": "Please enter the email address associated with your account and We will email you a link to reset your password.",
  "alreadyHaveCode": "Already have a code?",
  "verifyToken": "Verify token",
  "requestSentSuccessfully": "Request sent successfully!",
  "weHaveSentConfirmationEmail": "We have sent a confirmation email to",
  "pleaseCheckYourEmail": "Please check your email.",
  "codeSent": "Code sent",
  "emailNotValid": "A valid email has not been found, please fill in the email and send a new code",
  "confirmationEmailMessage": "We sent a confirmation code to your email to allow you to create a new password.",
  "notReceivedCode": "Didn't receive the code?",
  "resendCode": "Resend code",
  "tokenInvalid": "Invalid code",
  "neededCode": "Code is required",
  "verified": "Verify Success",
  "code": "Code",
  "verifyButton": "Verify",
  "firstAccessOrValidation": "This is your first access to the championship manager or for some security reason you need to validate your authentication, please enter your email and click Continue. We need this validation for you to enter the futstat manager.",
  "continue": "Continue",
  "isItYourFirstAccess": "Is it your first access? Click here!",
  "invalidEmail": "Invalid email!",
  "newPassword": "New password",
  "confirmNewPassword": "Confirm New password",
  "passwordsMustMatch": "Passwords must match",
  "passwordUpdatedPleaseReLogin": "Your password has been updated/created. Please put your credentials again!",
  "registerNewPassword": "Register new password",
  "requiredToAcceptTerms": "It is mandatory to accept the terms!",
  "confirmYourRegistration": "To follow we need your registration at Futstat, will take only a few minutes.",
  "status": "Status",
  "fields": {
    "subscriptionDate": "Subscription date",
    "alreadyPlayed": "Already played?",
    "fixedPlace":"Fixed place",
    "status": "Status",
    "logo": "Logo",
    "name": "Name",
    "dateOfBirth": "Date of birth",
    "email": "Email address",
    "phoneNumber": "Phone number",
    "cpf": "CPF (if you live in Brazil)",
    "docFederacao": "Doc Federation",
    "docCbf": "Doc Cbf",
    "docCbfs": "Doc Cbfs",
    "iReadAndAgreeWithTheTerms": "I read and agree with the terms of service and privacy policies.",
    "inChargeEmail": "Incharge email",
    "inChargeName": "Incharge name",
    "country": "Country",
    "state": "State",
    "city": "City",
    "modality": "Modality",
    "category": "Category",
    "gender": "Gender",
    "championshipType": "Type of championship",
    "season": "Season",
    "championshipContext": "Championship context",
    "showInApp": "Show in the app",
    "enrolPeriod": "Registration period",
    "numberOfTeams": "Number of teams",
    "numberOfGroups": "Number of groups",
    "inTheClassificationPhase": "In the classification phase",
    "maxYellowCardsFirstPhase": "Maximum yellow cards in the classification phase",
    "maxYellowCardsSecondPhase": "Maximum yellow cards in the eliminatory phase",
    "maxPlayersByTeam": "Maximum players by team",
    "maxTechnicalCommeteeByTeam": "Maximum technical commetee by team",
    "inTheEliminatoryPhase": "In the eliminatory phase",
    "numberOfTeamsPassingFromPhase": "Number of teams passing from phase",
    "inTheFinals": "In the finals",
    "tiebreakerCriteria": "Tiebreaker criteria",
    "managementMode": "management mode of the championship games table",
    "teamNickname": "Commercial name",
    "teamName": "Team name",
    "nickname": "Nickname",
    "code": "Code",
    "region": "Region",
    "colors": "Colors",
    "playerName": "Player name",
    "player": "Player",
    "position": "Position",
    "coach": "Coach",
    "doc": "Document",
    "phase": "Phase",
    "groupName": "Group",
    "round": "Round",
    "date": "Date",
    "hour": "Hour",
    "homesTeam": "Home's team",
    "regularHour": "Regular hour",
    "stoppageTime": "Stoppage time",
    "penaltyShootouts": "Penalty shootouts",
    "awaysTeam": "Away's team",
    "token": "Token",
    "place": "Place",
    "homesTeamName": "Home's team name",
    "awaysTeamName": "Away's team name",
    "groupNamefield": "Group name",
    "matchDate": "Match date",
    "gamesNumber": "Game",
    "undefinedTeams": "Teams are already defined",
    "game": "game",
    "gameOrder": "Game Of",
    "dtGame": "Game date",
    "hrGame": "Game time"
  },
  "saveChanges": "Save changes",
  "invalidDate": "Invalid date!",
  "registrationUpdated": "Registration updated!",
  "manageCompetitions": "Manage competitions",
  "competitionsList": "Competitions list",
  "chooseYourActionToManageCompetitionsAndChampionship": "Choose the desired action to manage your competitions and championships",
  "editCompetitionConfiguration": "Edit competition",
  "selectCompetition": "Show championships",
  "seeMore": "See more",
  "consultTeams": "Consult teams",
  "listMyCompetitions": "List my competitions",
  "createNewCompetition": "Create new competition",
  "selectedCompetition": "Selected competition",
  "change": "change",
  "modality": "Modality",
  "incharge": "Incharge",
  "seeLess": "See less",
  "category": "Category",
  "gender": "Gender",
  "championshipType": "Type of championship",
  "season": "Season",
  "type": "Type",
  "teams": "Teams",
  "show": "Show",
  "goToNextPage": "Go to next page",
  "of": "of",
  "campionshipName": "Championship name",
  "inChargeEmail": "Incharge email",
  "phoneNumberCantBeLessThan11": "Cell phone number cannot be less than 11 characters.",
  "competitions": "Competitions",
  "championships": "Championships",
  "manageYourChampionship": "Manage your campionships",
  "competitionCreatedSuccessfully": "Competition created successfully!",
  "competitionUpdatedSuccessfully": "Competition updated successfully!",
  "editCompetition": "Edit competition",
  "players": "Players",
  "add": "Add",
  "cancel": "Cancel",
  "save": "Save",
  "saveAndEditChampionships": "Save and edit championships",
  "createNewChampionship": "Create championship",
  "editChampionship": "Edit championship",
  "entity": "Entity",
  "selectTheYearThatSeasonWillFinish": "Select the year the championship ends. E.g.: Championship starts in oct. of 2024 and will end in Jan. 2025, then select the year 2025",
  "incorrectYearFormat": "Incorrect year format. Please enter the year with 4 digits.",
  "championshipCreatedSuccessfully": "Championship created successfully",
  "championshipUpdatedSuccessfully": "Championship updated successfully",
  "configureAthletesRegistration": "Configure the athletes' registration period in their respective teams according to the agenda of their competition",
  "minimumQuantity": "Minimum quantity: {{minQuantity}}",
  "acceptValues": "Accepted values: {{firstNumbers}} or {{lastNumber}}",
  "maxValueForNTeamsNext": "The quantity cannot be greater than the number of teams defined in the field above",
  "fillNumberOfTeamsField": "Fill in a valid value in the \"Number of teams\" field",
  "invalidNumberOfTeams": "Invalid quantity. There must be enough teams for each group to have the same number of teams. \nValid e.g.: 6 teams and 2 groups will be 3 teams per group \nInvalid e.g.: 5 teams and 3 groups, it is not possible to divide the teams equally into the groups",
  "System": "System",
  "Manual": "Manual",
  "invalidYear": "Invalid year. Please enter the year with 4 digits and larger than the current year",
  "finishChampionship": "Finish championship",
  "championshipFinished": "Championship Finished successfully!",
  "cancelChampionship": "Cancel championship",
  "championshipCanceled": "Championship Canceled successfully!",
  "areYouSureToCancelChampionship": "Are you sure you want to cancel the championship: {{CompetitionName}}?",
  "thisActionIsIrreversibleAndAfterDeletedThereIsNoReturn": "Essa é uma ação irreversível e após cancelado, este campeonato deixará de existir.",
  "setEnrolPeriod": "Set registration period",
  "setEnrolPeriodforChampionshipName": "Configure enrollment period for the championship: {{competitionName}}",
  "configureTheEnrollmentPeriod": "*Configure the athletes' enrollment period in their respective teams according to the agenda of their competition",
  "enrollmentPeriodUpdated": "Successful updated enrollment period!",
  "myTeams": "My teams",
  "allTeams": "All Teams",
  "createNewTeam": "Create new team",
  "championshipSelectedSuccessfully": "Showing teams from selected championship! To see other teams, select another championship.",
  "showTeams": "Show teams",
  "selectedChampionship": "Selected championship",
  "competitionSelectedSuccessfully": "Showing championship from selected competition! To see other championships, select another competition.",
  "manageYourTeams": "Manage your teams",
  "championship": "Championship",
  "team": "Team",
  "manageYourPlayers": "Manage your players",
  "messageSubscribePlayer": "Successfully registered",
  "messageEditSubscribePlayer": "Register edited successfully",
  "messageUnSubscribePlayer": "Subscription successfully removed",
  "tabAllPlayers": "Subscribed players",
  "tabAvailableForRegistration": "Pre-registered players",
  "subscribeActionMenuSubscribe": "Add player on competition",
  "subscribeActionMenuEdit": "Edit player on competition",
  "subscribeActionMenuRemove": "Remove subscription",
  "showPlayers": "Show players",
  "includePlayerOrCoachingStaff": "Include player and/or coaching staff",
  "competition": "Competition",
  "showMatches": "Show Matches",
  "createNewMacth": "Create new match",
  "manageYourMatches": "Manage your matches",
  "matches": "Matches",
  "match": "Match",
  "houseTeamCanBeEqualToAway": "House's team cannot be the same as the away's team",
  "awayTeamCanBeEqualToHouse": "Away's team cannot be the same as the house's team",
  "editMatch": "Edit Match",
  "listMatch": "List matches",
  "matchCreatedSuccessfully": "Match created successfully!",
  "matchUpdatedSuccessfully": "Match updated successfully!",
  "approveMatch": "Approve summary",
  "restoreMatch": "Restore summary",
  "cancelMatch": "Cancel match",
  "matchApproveSuccessfully": "Summary approved successfully",
  "matchRestoreSuccessfully": "Summary restored successfully",
  "matchCancelSuccessfully": "Match canceled successfully",
  "automaticMatchCreation": "Automatic creation of the games table of the championship: {{competitionName}}",
  "automaticMatchCreationDescription": "In the classification phase of the championship, the definition of intersections between the teams is maded by sortition. In the elimination phase, the intersections are made according to the team classification order in the championship, where the first place faces the last place, and so on. In addition, if the defined championship model is for roundtrip games, the return match is always decided at the home of the team best placed in the classification phase.",
  "areYouSureToCreateTheMatchs": "Do you want to create the matches of this championship? Choose your decision below the table.",
  "confirm": "Confirm",
  "theresAlreadyCreatedMatches": "This championship already has matches created! To redo it, delete the current matches and try again.",
  "matchTimeAndPlaceSuccessfully": "Time and place set successfully",
  "setTimePlace": "Set time and place",
  "setReferee": "Set match referee",
  "setRefereeForTheMatch": "Set referee for the match: {{round}} - {{houseTeam}} x {{awayTeam}}",
  "readTerms": "Read terms",
  "matchDeletedSuccessfully": "Match deleted successfully",
  "matchsDeletedSuccessfully": "Matchs deleted successfully",
  "deleteMatch": "Delete Match",
  "deleteAllMatches": "Delete all matches",
  "areYouSureToDeleteMatch": "Deleting the match: {{round}} - {{houseTeam}} x {{awayTeam}}?",
  "areYouSureToExecuteThisAction": "Are you sure to execute this action?",
  "youCanAlwaysRecreateAMatch": "You will always be able to recreate one or more matches by clicking on \"Create new match\" button.",
  "matchesCreatedSuccessfully": "Matchs created successfully",
  "thisOperationCanNotBeUnmade": "This action can not be undone!",
  "areYouSureToCancelMatch": "Caceling the match: {{round}} - {{houseTeam}} x {{awayTeam}}?",
  "isOnlyPossibleToEditMatchIfChampioionshipIsInConstruction": "It is not allowed to change a match in a started championship (with matches already done) or finished!",
  "isNotPossibleToEditAllMatchesIfChampioionshipIsNotInConstruction": "It is not allowed to change the all matches in a championship finished!",
  "coachingStaff": "Coaching staff",
  "all": "All",
  "invertMatch": "Invert match",
  "matchInvertedSuccessfully": "Match inverted susccessfully",
  "areYouSureToInvertMatch": "Invert the match: {{round}} - {{houseTeam}} x {{awayTeam}}?",
  "exportChampionship": "Export the championship",
  "exportTeam": "Export team",
  "addTeam": "Add team",
  "addTeamToChampionship": "Add team to the championship: {{championshipName}}",
  "teamAddedSuccessfully": "Team added to the championship successfully",
  "remove": "Remove",
  "addTeams": "Add teams",
  "teamAlreadyAdded": "Team already added",
  "teamRemovedSuccessfully": "Team removed from the championship successfully",
  "removeTeam": "Remove team",
  "championshipIsNotInConstructionPhase": "Championship is not in construction phase!",
  "championshipIsInFinishedPhase": "Championship alrerady is in finished phase!",
  "deleteTeam": "Remove team",
  "approveModalTitle": "Important!",
  "approveModalSubtitleOne": "When approving the summary, the statistics of the match/team/player and the teams scores in the competition officially become valid in the system!",
  "approveModalSubtitleTwo": "To confirm this operation, fill in the final conclusions of this match below",
  "approveModalSelectInputWinnerLabel": "Winner",
  "approveModalSelectInputWinnerValue": "Draw",
  "approveModalSelectInputIsWoLabel": "There was WO?",
  "approveModalSelectInputIsWoValueYes": "Yes",
  "approveModalSelectInputIsWoValueNo": "No",
  "approveModalSelectInputIsExtraTimeLabel": "There was extra time?",
  "approveModalSelectInputIsExtraTimeValueYes": "Yes",
  "approveModalSelectInputIsExtraTimeValueNo": "No",
  "approveModalActionsDescription": "Once you (the person responsible for the championship) approve the summary, it CANNOT BE CHANGED ANYMORE!",
  "approveModalActionButtonCancel": "Cancel",
  "approveModalActionButtonConfirm": "Approve",
  "areYouSureToRemovePlayer":"Are you sure you want to remove the player from pre-registration?",
  "areYouSureToRemovePlayerPlural":"Are you sure you want to remove the players from pre-registration?",
  "preRegistrationActionMenuRemove": "Remove from the pre-registration?",
  "messageRemoveFromPreRegisterPlayer":"Removed player from pre-registration",
  "teamDefinitionForEachGroup": "Definition of teams for each group of the championship:",
  "youCanSetEveryTeamForEachGroup": "You can define which teams will be in each group. To do this, just select the group, set your teams and then click 'Add'. After completing the process for all groups, click 'Save' to persist changes.",
  "youCanSortTeamsAutomatically": "If you prefer, the system can raffle teams for you. To do this, just click on 'Draw' and after checking the drew results, click 'Save' to persist changes.",
  "warningForManualGroupChampionship":"In the classification phase of the championship, the definition of intersections between the teams within each group is carried out by luck. In this option you have full control over the game table and definition of intersections between teams following your own classification and regulation criteria. But attention !! Playoff phase crossings should be inserted manually into the system by you as you opted for the manual management of the championship.",
  "typeAtLeast3Letters": "Type at least 3 letters",
  "noOptionFound": "No option found",
  "loading": "Loading",
  "addedToGroup":"Added to group:",
  "addOrRemoveTeamsFromGroup":"Add or remove teams from group",
  "updateGroup":"Update group",
  "selectTeamsPerGroup":"Select {{teamsNumber}} team(s) per group.",
  "teamsLimitReachedRemoveATeamIfYouNeedToChangeTheList":"Teams limit reached. Remove any team(s) if you need to change the list.",
  "randomizeThisGroupMatches":"Randomize this group matches",
  "sampleOfMatchesCreationInGroup":"Sample of matches creation in the group",
  "whenCreatingMatchesYouCanChooseBeteween2":"When creating matches for a group, you can choose between two form of creation: ordered or randomized:",
  "ordenedTypeExplanation":"Ordered type: The order of the teams is very important. In the first round, the team 1 will play against team 2, team 3 will play with team 4, and so on.",
  "randomizedTypeExplanation":"Randomized type: When randomizing, there will be no priority order and the first round will have the teams playing against each order randomly.",
  "youCanChooseTeamsInAnyMode":"In both modes, you can choose the teams that will be part of the group.",
  "teamsOrdered":"Team 1 x Team 2, Team 3 x Team 4, etc",
  "teamsRandomized":"Team 2 x Team 4, Team 3 x Team 1, etc",
  "orderThisGroupMatches":"Order matches in group",
  "randomizeAllGroups":"Randomize all groups",
  "cleanFields":"Clean all fields",
  "randomizeAllGroupsTooltip":"Shuffling all groups will randomly define the teams in each group",
  "randomizeAllGroupsDescription":"This will erase the current team selection for the groups.",
  "friendlyGame":"Friendly game",
  "maxTeamNumberReached":"Maximum number of teams achieved, remove a team to add a new one.",
  "qtyChampionship":"Qty. of championships",
  "summary": "Summary",
  "gameSummary": "Game summary",
  "summarySucccessfullyLoaded":"Summary loaded sucessfully",
  "competitionGroupIsUnavailable":"Competition group is unavailable.",
  "seeCompetitionGroup":"See competition group",  
  "seeSummary": "See summary",
  "summaryOccurences": {
    "action": "Action",
    "playerName" : "Player",
    "playerNumber" : "Player nr.",
    "playerPosition" : "Player pos.",
    "periodTime" : "Match period",
    "period" : "Phase",
    "gameHour" : "Time",
    "scoredGoals": "SCORED GOALS",
    "subtitleDescription" : "(Starts with the most recent occurrences of the match)",
    "faultsAndCards": "FAULTS AND CARDS APPLIED"
  },
  "summaryApprove":{
    "title": "Important!",
    "body1": "When approving the summary, the statistics of the match / team / player and the team's scores in the competition officially become valid in the system!",
    "body2": "Once you (responsible for the championship) approve the summary, it CANNOT BE CHANGED ANYMORE!",
    "body3": "To confirm this operation, fill in the final conclusions of this match below.",
    "winner": "Winner",
    "hasOvertime": "Has Overtime",
    "hasWo": "Has wo",
    "approove": "Approove",
    "approoved": "Approoved",
    "classifiedTeam":"Classified team",
    "draw":"Draw",
    "no": "No",
    "yes": "Yes",
    "canceled": "Canceled",
    "preApprooved": "Pre-Approoved"
  },
  "noResultsFound":"No results found",
  "createTeam":"Create team",
  "teamCreated":"Team created successfully",
  "editTeam":"Editar time",
  "teamEdited":"Team edited successfully",
  "areYouSureToFinishChampionship": "Are you sure you want to finish the championship: {{CompetitionName}}?",
  "isNotPossibleToEditMatchIfChampioionshipIsFinished": "It is not allowed to change the a match in a championship finished!",
  "matchWasCanceled":"Match was canceled",
  "unsubscribed": "Unsubscribed",
  "subscribed": "Subscribed",
  "expandTable": "Expand table",
  "unExpandTable": "Unexpand table",
  "expandTableRows": "Expand table rows",
  "unExpandTableRows": "Unexpand table rows",
  "see": "See",
  "dragTheFileOr": "Drag the file or",
  "inYourComputer": "In your computer",
  "sendMaxFileSize": "Send max file size",
  "sendFile": "Send file",
  "editFile": "Edit file",
  "colors2": "Colors",
  "noOptions": "No options...",
  "playerHasMatchOccurrences": "Player cannot be unregistered as he has already been selected",
  "tabTechnicalCommittee": "Technical committee",
  "sendInvites": "Send invites",
  "sentInvites": "Invites have been sent",
  "addOnlyEmail": "Add only email",
  "searchForANameToPickUpPlayersForSendingTheInvites": "Search for a name to pick up players for sending the invites",
  "send": "Send",
  "colors": {
    "violetBlue": "Violet Blue",
    "brightTurquoiseBlue": "Bright Turquoise Blue",
    "turquoiseBlue": "Turquoise Blue",
    "tokyoBlue": "Tokyo Blue",
    "taparuereBlue": "Taparuere Blue",
    "royalBlue": "Royal Blue",
    "gunpowderBlue": "Gunpowder Blue",
    "tealBlue": "Teal Blue",
    "midnightBlue": "Midnight Blue",
    "mediumBlue": "Medium Blue",
    "navyBlue": "Navy Blue",
    "butterBlue": "Butter Blue",
    "stealthBlue": "Stealth Blue",
    "airForceBlue": "Air Force Blue",
    "cornflowerBlue": "Cornflower Blue",
    "darkBlue": "Dark Blue",
    "cobaltBlue": "Cobalt Blue",
    "lightBlue": "Light Blue",
    "deepSkyBlue": "Deep Sky Blue",
    "lightSkyBlue": "Light Sky Blue",
    "skyBlue": "Sky Blue",
    "brightSkyBlue": "Bright Sky Blue",
    "celeste": "Celeste",
    "comradeBlue": "Comrade Blue",
    "cadetBlue": "Cadet Blue",
    "brilliantBrazilianBlue": "Brilliant Brazilian Blue",
    "brazilianBlue": "Brazilian Blue",
    "sandyBlue": "Sandy Blue",
    "mediumSlateBlue": "Medium Slate Blue",
    "darkSlateBlue": "Dark Slate Blue",
    "lightSlateBlue": "Light Slate Blue",
    "slateBlue": "Slate Blue",
    "aliceBlue": "Alice Blue",
    "lightSteelBlue": "Light Steel Blue",
    "steelBlue": "Steel Blue",
    "blue": "Blue",
    "asparagus": "Asparagus",
    "amethyst": "Amethyst",
    "almond": "Almond",
    "plum": "Plum",
    "amber": "Amber",
    "burntYellow": "Burnt Yellow",
    "lightGoldYellow": "Light Gold Yellow",
    "greenishYellow": "Greenish Yellow",
    "darkYellow": "Dark Yellow",
    "creamYellow": "Cream Yellow",
    "lightYellow": "Light Yellow",
    "brazilianYellow": "Brazilian Yellow",
    "yellow": "Yellow",
    "alizarin": "Alizarin",
    "mediumAquamarine": "Medium Aquamarine",
    "aquamarine": "Aquamarine",
    "water": "Water",
    "saffron": "Saffron",
    "beige": "Beige",
    "burgundy": "Burgundy",
    "white": "White",
    "antiqueWhite": "Antique White",
    "ghostWhite": "Ghost White",
    "floralWhite": "Floral White",
    "smokeWhite": "Smoke White",
    "navajoWhite": "Navajo White",
    "brazil": "Brazil",
    "bronze": "Bronze",
    "khaki": "Khaki",
    "thistle": "Thistle",
    "crimson": "Crimson",
    "carmine": "Carmine",
    "classicCarmine": "Classic Carmine",
    "carnaceousCrimson": "Carnaceous Crimson",
    "reddishBrown": "Reddish Brown",
    "lightBrown": "Light Brown",
    "carrot": "Carrot",
    "cherry": "Cherry",
    "hollywoodCherry": "Hollywood Cherry",
    "chocolate": "Chocolate",
    "cyan": "Cyan",
    "lightCyan": "Light Cyan",
    "darkCyan": "Dark Cyan",
    "gray": "Gray",
    "slateGray": "Slate Gray",
    "lightSlateGray": "Light Slate Gray",
    "darkSlateGray": "Dark Slate Gray",
    "lightGray": "Light Gray",
    "darkGray": "Dark Gray",
    "dullGray": "Dull Gray",
    "mediumGray": "Medium Gray",
    "copper": "Copper",
    "shell": "Shell",
    "coral": "Coral",
    "lightCoral": "Light Coral",
    "leather": "Leather",
    "cream": "Cream",
    "creamShellfish": "Cream Shellfish",
    "creamOfMint": "Cream Of Mint",
    "darkKhaki": "Dark Khaki",
    "dainise": "Dainise",
    "golden": "Golden",
    "darkGold": "Dark Gold",
    "paleGold": "Pale Gold",
    "ebony": "Ebony",
    "eminence": "Eminence",
    "scarlet": "Scarlet",
    "emerald": "Emerald",
    "eucalyptus": "Eucalyptus",
    "fandango": "Fandango",
    "feldspar": "Feldspar",
    "rust": "Rust",
    "flirt": "Flirt",
    "soot": "Soot",
    "fuchsia": "Fuchsia",
    "maroon": "Maroon",
    "gainsboro": "Gainsboro",
    "glitter": "Glitter",
    "guava": "Guava",
    "herbal": "Herbal",
    "heliotrope": "Heliotrope",
    "indigo": "Indigo",
    "independence": "Independence",
    "iris": "Iris",
    "jade": "Jade",
    "jambo": "Jambo",
    "jasmine": "Jasmine",
    "kiwi": "Kiwi",
    "kobi": "Kobi",
    "kobicha": "Kobicha",
    "orange": "Orange",
    "darkOrange": "Dark Orange",
    "lightOrange": "Light Orange",
    "lavender": "Lavender",
    "reddishLavender": "Reddish Lavender",
    "lilac": "Lilac",
    "lime": "Lime",
    "lemon": "Lemon",
    "linen": "Linen",
    "wood": "Wood",
    "magenta": "Magenta",
    "darkMagenta": "Dark Magenta",
    "mauve": "Mauve",
    "papayaBlended": "Papaya Blended",
    "manna": "Manna",
    "ivory": "Ivory",
    "yellowishBrown": "Yellowish Brown",
    "pinkishBrown": "Pinkish Brown",
    "brownSaddle": "Brown Saddle",
    "corn": "Corn",
    "lightCorn": "Light Corn",
    "moccasin": "Moccasin",
    "mustard": "Mustard",
    "navy": "Navy",
    "snow": "Snow",
    "nyanza": "Nyanza",
    "ochre": "Ochre",
    "olive": "Olive",
    "darkOlive": "Dark Olive",
    "brownOlive": "Brown Olive",
    "orchid": "Orchid",
    "darkOrchid": "Dark Orchid",
    "mediumOrchid": "Medium Orchid",
    "gold": "Gold",
    "brown": "Brown",
    "darkBrown": "Dark Brown",
    "silver": "Silver",
    "black": "Black",
    "peach": "Peach",
    "purple": "Purple",
    "mediumPurple": "Medium Purple",
    "quantum": "Quantum",
    "quartz": "Quartz",
    "antiqueLace": "Antique Lace",
    "pink": "Pink",
    "brightPink": "Bright Pink",
    "lovePink": "Love Pink",
    "lightPink": "Light Pink",
    "damnPink": "Damn Pink",
    "dullPink": "Dull Pink",
    "hotPink": "Hot Pink",
    "deepPink": "Deep Pink",
    "brazilianPurple": "Brazilian Purple",
    "rutile": "Rutile",
    "salmon": "Salmon",
    "lightSalmon": "Light Salmon",
    "darkSalmon": "Dark Salmon",
    "siena": "Siena",
    "sepia": "Sepia",
    "tangerine": "Tangerine",
    "terracotta": "Terracotta",
    "firebrick": "Firebrick",
    "tomato": "Tomato",
    "wheat": "Wheat",
    "triassic": "Triassic",
    "turquoise": "Turquoise",
    "dark turquoise": "Dark Turquoise",
    "medium turquoise": "Medium Turquoise",
    "turquoise pale": "Turquoise Pale",
    "ube": "Ube",
    "annatto": "Annatto",
    "green": "Green",
    "spectrumGreen": "Spectrum Green",
    "yellowishGreen": "Yellowish Green",
    "lightGreen": "Light Green",
    "darkGreen": "Dark Green",
    "forestGreen": "Forest Green",
    "fluorescentGreen": "Fluorescent Green",
    "limeGreen": "Lime Green",
    "grassGreen": "Grass Green",
    "lightSeaGreen": "Light Sea Green",
    "darkSeaGreen": "Dark Sea Green",
    "mediumSeaGreen": "Medium Sea Green",
    "militaryGreen": "Military Green",
    "oliveGreen": "Olive Green",
    "parisGreen": "Paris Green",
    "springGreen": "Spring Green",
    "mediumSpringGreen": "Medium Spring Green",
    "paleGreen": "Pale Green",
    "blueGreen": "Blue Green",
    "red": "Red",
    "blackishRed": "Blackish Red",
    "darkRed": "Dark Red",
    "redIndian": "Red Indian",
    "redViolet": "Red Violet",
    "mediumRedViolet": "Medium Red Violet",
    "paleRedViolet": "Pale Red Violet",
    "violet": "Violet",
    "darkViolet": "Dark Violet",
    "lightViolet": "Light Violet",
    "xanadu": "Xanadu"    
  },
  "positions": {
    "goalkeeper": "Goalkeeper",
    "winger": "Winger",
    "pivot": "Pivot",
    "fixed": "Fixed",
    "defender": "Defender",
    "full-back": "Full-back",
    "defensiveMidfielder": "Defensive Midfielder",
    "midfielder": "Midfielder",
    "striker": "Striker",
    "centerForward": "Center-Forward",
    "representative": "Representative",
    "doctor": "Doctor",
    "coach": "Coach",
    "physiotherapist": "Physiotherapist",
    "performanceAnalyst": "Performance Analyst",
    "masseur": "Masseur",
    "physicalTrainer": "PhysicalTrainer",
    "supervisor": "Supervisor",
    "technicalAssistant": "Technical Assistant",
    "goalkeeperTrainer": "Goalkeeper Trainer"
  },
  "editPlayer":"Edit Player",
  "showChampionshipId": "Show championship id",
  "showChampionshipIdModalMinorDescription": "NOTE: If necessary, contact the Futstat Team for more information.",
  "showChampionshipIdModalDescription": "Use this identification code to consult (via API) the information of the selected championship:",
  "showChampionshipIdModalTitle": "Copy the championship identification code?",
  "copiedCodeSuccessfully": "Copied code successfully",
  "createGamesTable":"Create games table",
  "refereeSetSuccessfully":"referee set successfully!",
  "update":"Update",
  "clear":"Clear",
  "somethingWentWrongWithTheMatchUpdate": "An error occurred while updating the {{game}}th game",
  "somethingWentWrong": "An error occurred while performing this action"
}
