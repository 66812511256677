{
  "title": "Español",
  "signIncompetitionManager": {
    "signIn": "Entrar",
    "welcome": "Sea bienvenido",
    "signInDetails": "Confirme la información a continuación."
  },
  "LoginSuccess": "Acceso exitoso",
  "Login": "Acceso",
  "InvalidLogin": "Contraseña o usuario inválido.",
  "search": "Buscar",
  "logout": "Cerrar sesión",
  "logoutUnable": "¡Incapaz de cerrar sesión!",
  "emailRequired": "¡El correo electrónico es obligatorio!",
  "passwordRequired": "¡Se requiere Contraseña!",
  "emailLabel": "Correo electrónico",
  "passwordLabel": "Contraseña",
  "titleRequired": "¡El Título es obligatorio!",
  "dateRequired": "¡La fecha es obligatoria!",
  "fieldRequired": "¡El campo es obligatorio!",
  "championshipManager": "Gestión de campeonatos",
  "inputInitialDate": "Fecha inicial",
  "inputFinalDate": "Fecha Final",
  "startDateCantBeGreaterThanFinalDate": "¡La fecha de inicio no puede ser mayor que la fecha final!",
  "accountSettings": "Configuraciones de la cuenta",
  "settings": "Configuraciones",
  "forgotPassword": "¿Has olvidado tu contraseña?",
  "resetPassword": "Restablecer la contraseña",
  "back": "Volver",
  "msgEnterEmailToResetPassword": "Ingrese la dirección de correo electrónico asociada con su cuenta y le enviaremos un enlace por correo electrónico para restablecer su contraseña.",
  "alreadyHaveCode": "¿Ya tienes un código?",
  "verifyToken": "Verificar token",
  "requestSentSuccessfully": "¡Solicitud enviada con éxito!",
  "weHaveSentConfirmationEmail": "Hemos enviado un correo electrónico de confirmación a",
  "pleaseCheckYourEmail": "Por favor revise su correo electrónico.",
  "codeSent": "Código enviado!",
  "emailNotValid": "No se ha encontrado un correo electrónico válido, complete el correo electrónico y envíe un nuevo código",
  "confirmationEmailMessage": "Le enviamos un código de confirmación a su correo electrónico para permitirle crear una nueva contraseña.",
  "notReceivedCode": "¿No recibió el código?",
  "resendCode": "Reenviar código",
  "tokenInvalid": "Código no válido",
  "neededCode": "El código es obligatorio",
  "verified": "Verificado correctamente",
  "code": "Código",
  "verifyButton": "Verificar",
  "firstAccessOrValidation": "Este es su primer acceso al Gerente de Campeonato o por alguna razón de seguridad, debe validar su autenticación, ingrese su correo electrónico y haga clic en Continuar. Necesitamos esta validación para que ingrese al administrador de Futstat.",
  "continue": "Continuar",
  "isItYourFirstAccess": "¿Es tu primer acceso? ¡Haga clic aquí!",
  "invalidEmail": "¡Correo electrónico inválido!",
  "newPassword": "Nueva contraseña",
  "confirmNewPassword": "Confirme nueva contraseña",
  "passwordsMustMatch": "Las contraseñas no coinciden",
  "passwordUpdatedPleaseReLogin": "¡Su contraseña ha sido actualizada/creada. ¡Vuelva a colocar sus credenciales!",
  "registerNewPassword": "Registrar nueva contraseña",
  "requiredToAcceptTerms": "¡Es obligatorio aceptar los términos!",
  "confirmYourRegistration": "Para seguir, necesitamos su registro en Futstat, tomaremos solo unos minutos.",
  "status": "Status",
  "fields": {
    "subscriptionDate": "Fecha de registro",
    "alreadyPlayed": "¿Ya jugué?",
    "fixedPlace":"Ubicación fija",
    "status": "Status",
    "logo": "Escudo",
    "name": "Nombre",
    "dateOfBirth": "Fecha de nacimiento",
    "email": "Correo electrónico",
    "phoneNumber": "Número de móvil",
    "cpf": "CPF (Si vives en Brasil)",
    "rg": "Cédula de identidad",
    "docFederacao": "Doc Federacion",
    "docCbf": "Doc Cbf",
    "docCbfs": "Doc Cbfs",
    "iReadAndAgreeWithTheTerms": "Leí y estoy de acuerdo con los términos de servicio y políticas de privacidad.",
    "inChargeEmail": "Correo electrónico del responsable",
    "inChargeName": "Nombre del responsable",
    "country": "País",
    "state": "Estado",
    "city": "Ciudad",
    "modality": "Modalidad",
    "category": "Categoría",
    "gender": "Género",
    "championshipType": "Tipo de campeonato",
    "season": "Estación",
    "championshipContext": "Contexto del campeonato",
    "showInApp": "Mostrar en la aplicación",
    "enrolPeriod": "Periodo de registro",
    "numberOfTeams": "Número de equipos",
    "numberOfGroups": "Número de grupos",
    "inTheClassificationPhase": "En la fase de clasificación",
    "maxYellowCardsFirstPhase": "Tarjetas amarillas máximas en la fase de clasificación",
    "maxYellowCardsSecondPhase": "Tarjetas amarillas máximas en la fase eliminatoria",
    "maxPlayersByTeam": "Jugadores máximos por equipo",
    "maxTechnicalCommeteeByTeam": "Comité técnico máximo por equipo",
    "inTheEliminatoryPhase": "En la fase eliminatoria",
    "numberOfTeamsPassingFromPhase": "Número de equipos que pasan de fase",
    "inTheFinals": "En la final",
    "tiebreakerCriteria": "Criterios de desempate",
    "managementMode": "Modo de gestión del tablón de juego de campeonato",
    "teamNickname": "Nombre fantasía",
    "teamName": "Nombre del equipo",
    "nickname": "Apelido",
    "code": "Código",
    "region": "Región",
    "colors": "Colores",
    "playerName": "Nombre del jugador",
    "player": "Jugador",
    "position": "Posición",
    "coach": "Técnico",
    "doc": "Documento",
    "phase": "Fase",
    "groupName": "Grupo",
    "round": "Partida",
    "date": "Fecha",
    "hour": "Hora",
    "homesTeam": "Equipo de la casa",
    "regularHour": "Hora regular",
    "stoppageTime": "Prórroga",
    "penaltyShootouts": "Penales",
    "awaysTeam": "Equipo visitante",
    "token": "Token",
    "place": "sitio",
    "homesTeamName": "Nombre del equipo de la casa",
    "awaysTeamName": "Nombre del equipo visitante",
    "groupNamefield": "Nombre del grupo",
    "matchDate": "Fecha del partido",
    "gamesNumber": "Juego",
    "undefinedTeams": "Equipos jáya definidos",
    "game": "Juego",
    "gameOrder": "Juego de",
    "dtGame": "Fecha de juego",
    "hrGame": "Horario del juego"
  },
  "saveChanges": "Guardar cambios",
  "invalidDate": "¡Fecha inválida!",
  "registrationUpdated": "¡Registro actualizado!",
  "manageCompetitions": "Administrar competiciones",
  "competitionsList": "Lista de competiciones",
  "chooseYourActionToManageCompetitionsAndChampionship": "Elija la acción deseada para administrar sus competiciones y campeonatos",
  "editCompetitionConfiguration": "Editar competición",
  "selectCompetition": "Mostrar campeonatos",
  "seeMore": "Ver más",
  "consultTeams": "Ver equipos",
  "listMyCompetitions": "Enumerar mis competiciones",
  "createNewCompetition": "Crear nueva competición",
  "selectedCompetition": "Competición seleccionada",
  "change": "cambiar",
  "modality": "Modalidad",
  "incharge": "Responsable",
  "seeLess": "Ver menos",
  "category": "Categoría",
  "gender": "Género",
  "championshipType": "Tipo de campeonato",
  "season": "Estación",
  "type": "Tipo",
  "teams": "Equipos",
  "show": "Mostrar",
  "goToNextPage": "Vaya a la página siguiente",
  "of": "de",
  "campionshipName": "Nombre del campeonato",
  "inChargeEmail": "Correo electrónico del responsable",
  "phoneNumberCantBeLessThan11": "El número de teléfono celular no puede ser inferior a 11 caracteres.",
  "competitions": "Competiciones",
  "championships": "Campeonatos",
  "manageYourChampionship": "Administre sus campeonatos",
  "competitionCreatedSuccessfully": "Competición creada con éxito!",
  "competitionUpdatedSuccessfully": "Competición cambiada con éxito!",
  "editCompetition": "Editar competición",
  "players": "Jugadores",
  "add": "Agregar",
  "cancel": "Cancelar",
  "save": "Guardar",
  "saveAndEditChampionships": "Guardar y editar campeonatos",
  "createNewChampionship": "Crear campeonato",
  "editChampionship": "Editar campeonato",
  "entity": "Entidad",
  "selectTheYearThatSeasonWillFinish": "Seleccione el año en que termina el campeonato. Ej.: El campeonato comienza en oct. de 2024 y finalizará en ene. de 2025, luego seleccione el año 2025",
  "incorrectYearFormat": "Formato de año incorrecto. Ingrese el año con 4 dígitos.",
  "championshipCreatedSuccessfully": "Campeonato creado con éxito",
  "championshipUpdatedSuccessfully": "Campeonato cambiado con éxito",
  "configureAthletesRegistration": "Configure el período de registro de los atletas en sus respectivos equipos de acuerdo con la agenda de su competición",
  "minimumQuantity": "Cantidad mínima: {{minQuantity}}",
  "acceptValues": "valores aceptados: {{firstNumbers}} o {{lastNumber}}",
  "maxValueForNTeamsNext": "La cantidad no puede ser mayor que el número de equipos definidos en el campo anterior",
  "fillNumberOfTeamsField": "Complete un valor válido en el campo \"Número de equipos\"",
  "invalidNumberOfTeams": "Cantidad inválida. Debe haber suficientes equipos para que cada grupo tenga el mismo número de equipos. \nEx. Válido: 6 equipos y 2 grupos, serán 3 equipos por grupo \nEx. Inválidos: 5 equipos y 3 grupos, no es posible dividir los equipos por igual en los grupos",
  "System": "Sistema",
  "Manual": "Manual",
  "invalidYear": "Año inválido. Ingrese el año con 4 dígitos y más grande que el año en curso",
  "finishChampionship": "Terminar el campeonato",
  "championshipFinished": "¡El campeonato he sido terminado con éxito!",
  "cancelChampionship": "Cancelar el campeonato",
  "championshipCanceled": "¡El campeonato he sido cancelado con éxito!",
  "areYouSureToCancelChampionship": "Estás seguro de que quieres cancelar el campeonato: {{CompetitionName}}?",
  "thisActionIsIrreversibleAndAfterDeletedThereIsNoReturn": "Essa é uma ação irreversível e após eliminado, este campeonato deixará de existir.",
  "setEnrolPeriod": "Configurar el periodo de registro",
  "setEnrolPeriodforChampionshipName": "Configurar el período de registro para el campeonato: {{competitionName}}",
  "configureTheEnrollmentPeriod": "*Configure el período de registro de los atletas en sus respectivos equipos de acuerdo con la agenda de su competencia:",
  "enrollmentPeriodUpdated": "¡Período de registro actualizado exitosamente!",
  "myTeams": "Mis equipos",
  "allTeams": "Todos los equipos",
  "createNewTeam": "Crear nuevo equipo",
  "championshipSelectedSuccessfully": "¡Mostrando equipos del campeonato seleccionado! Para ver otros equipos, seleccione otro campeonato.",
  "showTeams": "Mostrar equipos",
  "selectedChampionship": "Campeonato seleccionada",
  "competitionSelectedSuccessfully": "¡Mostrando campeonatos de la competición seleccionado! Para ver otros campeonatos, seleccione otra competición.",
  "manageYourTeams": "Administre sus equipos",
  "championship": "Campeonato",
  "team": "Equipo",
  "manageYourPlayers": "Administre sus jugadores",
  "messageSubscribePlayer": "Registrado con éxito",
  "messageEditSubscribePlayer": "Registro editado exitosamente",
  "messageUnSubscribePlayer": "Suscripción eliminada con éxito",
  "tabAllPlayers": "Jugadores inscritos",
  "tabAvailableForRegistration": "Jugadores preinscritos",
  "subscribeActionMenuSubscribe": "Regístrate para el campeonato",
  "subscribeActionMenuEdit": "Editar registro",
  "subscribeActionMenuRemove": "Eliminar suscripción",
  "showPlayers": "Motrar jugadores",
  "includePlayerOrCoachingStaff": "Incluya jugador y/o cuerpo técnico.",
  "competition": "Competición",
  "showMatches": "Mostrar partidos",
  "createNewMacth": "Crear nuevo partido",
  "manageYourMatches": "Administre sus partidos",
  "matches": "Tabla de partidos",
  "match": "Partido",
  "houseTeamCanBeEqualToAway": "El equipo de la casa no puede ser el mismo que el equipo visitante",
  "awayTeamCanBeEqualToHouse": "El equipo visitante no puede ser el mismo que el equipo de la casa",
  "editMatch": "Editar partido",
  "listMatch": "Enumerar partidos",
  "matchCreatedSuccessfully": "¡Partido creado con successo!",
  "matchUpdatedSuccessfully": "¡Partido atualizada con successo!",
  "approveMatch": "Aprovar sumario",
  "restoreMatch": "Restaurar sumario",
  "cancelMatch": "Cancelar partido",
  "matchApproveSuccessfully": "Sumario aprobado exitosamente",
  "matchRestoreSuccessfully": "Sumario restaurado exitosamente",
  "matchCancelSuccessfully": "Partido cancelado exitosamente",
  "automaticMatchCreation": "Creación automática de los partidos del campeonato: {{competitionName}}",
  "automaticMatchCreationDescription": "En la fase de clasificación del campeonato, la definición de intersecciones entre los equipos se lleva a cabo por sorteo. En la fase de eliminación, las intersecciones se realizan de acuerdo con la orden de clasificación del equipo en el campeonato, donde el primer lugar enfrenta el último lugar, y así sucesivamente. Además, si el modelo de campeonato definido es para juegos de ida y vuelta, el partido de regreso siempre se decide en la casa del equipo mejor ubicado en la fase de clasificación.",
  "areYouSureToCreateTheMatchs": "¿Quieres crear los partidos de este campeonato? Elija su decisión debajo de la tabla.",
  "confirm": "Confirmar",
  "theresAlreadyCreatedMatches": "¡Este campeonato ya tiene partidos creados! Para rehacerlo, elimine los partidos actuales e intente nuevamente.",
  "matchTimeAndPlaceSuccessfully": "Hora y lugar configurados correctamente",
  "setTimePlace": "Establecer hora y lugar",
  "setReferee": "Definir árbitro del partido",
  "setRefereeForTheMatch": "Definir árbitro del partido: {{round}} - {{houseTeam}} x {{awayTeam}}",
  "readTerms": "Leer los términos",
  "matchDeletedSuccessfully": "Partido eliminado exitosamente",
  "matchsDeletedSuccessfully": "Partidos eliminados exitosamente",
  "deleteMatch": "Eliminar partido",
  "deleteAllMatches": "Eliminar todos los partidos",
  "areYouSureToDeleteMatch": "¿Estás seguro de que quieres eliminar el partido: {{round}} - {{houseTeam}} x {{awayTeam}}?",
  "areYouSureToExecuteThisAction": "¿Estás seguro de que quieres realizar esta acción?",
  "youCanAlwaysRecreateAMatch": "Siempre puede recrear uno o más partidos haciendo clic en el botón \"Crear nuevo partido\".",
  "matchesCreatedSuccessfully": "Partidos creados exitosamente",
  "thisOperationCanNotBeUnmade": "¡Esta acción no se puede deshacer!",
  "areYouSureToCancelMatch": "Cancelar el partido: {{round}} - {{houseTeam}} x {{awayTeam}}?",
  "isOnlyPossibleToEditMatchIfChampioionshipIsInConstruction": "¡No és permitido cambiar el partido en un campeonato iniciado (con partidos ya realizados) o terminado!",
  "isNotPossibleToEditAllMatchesIfChampioionshipIsNotInConstruction": "¡No és permitido cambiar todos los partidos en un campeonato con el estado terminado!",
  "coachingStaff": "Cuerpo técnico",
  "all": "Todos",
  "invertMatch": "Invertir el partido",
  "matchInvertedSuccessfully": "Partido invertido exitosamente",
  "areYouSureToInvertMatch": "Invertir el partido: {{round}} - {{houseTeam}} x {{awayTeam}}?",
  "exportChampionship": "Exportar el campeonato",
  "exportTeam": "Exportar el equipo",
  "addTeam": "Agregar equipo",
  "addTeamToChampionship": "Agregar equipo en el campeonato: {{championshipName}}",
  "teamAddedSuccessfully": "Equipo agregado en el campeonato exitosamente",
  "remove": "Eliminar",
  "addTeams": "Agregar equipos",
  "teamAlreadyAdded": "El equipo ya ha sido agregado",
  "teamRemovedSuccessfully": "Equipo eliminado de el campeonato exitosamente",
  "removeTeam": "Eliminar equipo del campeonato",
  "championshipIsNotInConstructionPhase": "¡El campeonato no está en la fase de construcción!",
  "championshipIsInFinishedPhase": "¡El campeonato ja está en la fase de finalizacion!",
  "deleteTeam": "Eliminar equipo",
  "approveModalTitle": "Importante!",
  "approveModalSubtitleOne": "Al aprobar el resumen, las estadísticas del partido/equipo/jugador y las puntuaciones de los equipos en la competición se vuelven oficialmente válidas en el sistema!",
  "approveModalSubtitleTwo": "Para confirmar esta operación, completa las conclusiones finales de este partido.",
  "approveModalSelectInputWinnerLabel": "Ganador",
  "approveModalSelectInputWinnerValue": "Empate",
  "approveModalSelectInputIsWoLabel": "Ha ocurrido un WO?",
  "approveModalSelectInputIsWoValueYes": "Sí",
  "approveModalSelectInputIsWoValueNo": "No",
  "approveModalSelectInputIsExtraTimeLabel": "El partido fue a la prórroga?",
  "approveModalSelectInputIsExtraTimeValueYes": "Sí",
  "approveModalSelectInputIsExtraTimeValueNo": "No",
  "approveModalActionsDescription": "Una vez que usted (el responsable del campeonato) apruebe el resumen, ¡NO SE PUEDE CAMBIAR MÁS!",
  "approveModalActionButtonCancel": "Cancelar",
  "approveModalActionButtonConfirm": "Aprobar",
  "areYouSureToRemovePlayer":"¿Estás seguro de que quieres eliminar el jugador del prerregistro?",
  "areYouSureToRemovePlayerPlural":"¿Estás seguro de que quieres eliminar el jugadores del prerregistro?",
  "preRegistrationActionMenuRemove": "Eliminar del prerregistro",
  "messageRemoveFromPreRegisterPlayer":"Jugador eliminado del prerregistro",
  "teamDefinitionForEachGroup": "Definición de equipos para cada grupo del campeonato:",
  "youCanSetEveryTeamForEachGroup": "Puede definir qué equipos estarán en cada grupo. Para hacer esto, simplemente seleccione el grupo, configure sus equipos y luego haga clic en 'Agregar'. Después de completar el proceso para todos los grupos, haga clic en 'Guardar' para persistir los cambios.",
  "youCanSortTeamsAutomatically": "Si lo prefiere, el sistema puede sortear equipos para usted. Para hacer esto, simplemente haga clic en 'sortear' y después de verificar los resultados dibujados, haga clic en 'Guardar' para persistir los cambios.",
  "warningForManualGroupChampionship":"En la fase de clasificación del campeonato, la suerte se lleva a cabo la definición de intersecciones entre los equipos dentro de cada grupo. En esta opción, tiene el control total sobre la tabla de juegos y la definición de intersecciones entre equipos que siguen sus propios criterios de clasificación y regulación. ¡Pero atención! Los cruces de fase de playoffs deben ser insertados manualmente en el sistema por usted, ya que ha optado por la gestión manual del campeonato.",
  "typeAtLeast3Letters": "Escriba al menos 3 letras",
  "noOptionFound": "No se ha encontrado ninguna opción",
  "loading": "Cargando",
  "addedToGroup":"Agregado en el grupo:",
  "addOrRemoveTeamsFromGroup":"Agregar o eliminar equipos del grupo",
  "updateGroup":"Actualizar grupo",
  "selectTeamsPerGroup":"Seleccione {{teamsNumber}} equipo(s) por grupo.",
  "teamsLimitReachedRemoveATeamIfYouNeedToChangeTheList":"Llegaste al número máximo de equipos. Elimine cualquier equipo(s) si necesita cambiar la lista.",
  "randomizeThisGroupMatches":"Aleatorizar los partidos de este grupo",
  "sampleOfMatchesCreationInGroup":"Ejemplos de creación de partidos en el grupo",
  "whenCreatingMatchesYouCanChooseBeteween2":"Al crear coincidencias para un grupo, puede elegir entre dos forma de creación: ordenado o aleatorio:",
  "ordenedTypeExplanation":"Tipo ordenado: el orden de los equipos es muy importante. En la primera ronda, el Equipo 1 jugará contra el Equipo 2, el Equipo 3 jugará con el Equipo 4, y así sucesivamente.",
  "randomizedTypeExplanation":"Tipo aleatorio: al aleatorizar, no habrá orden de prioridad y la primera ronda hará que los equipos jueguen contra de modo aleatorio.",
  "youCanChooseTeamsInAnyMode":"En ambos modos, puede elegir los equipos que serán parte del grupo.",
  "teamsOrdered":"Equipo 1 x Equipo 2, Equipo 3 x Equipo 4, etc",
  "teamsRandomized":"Equipo 2 x Equipo 4, Equipo 3 x Equipo 1, etc",
  "orderThisGroupMatches":"Ordenar partidos en el grupo",
  "randomizeAllGroups":"Barajar todos los grupos",
  "cleanFields":"Limpiar todos los campos",
  "randomizeAllGroupsTooltip":"Barajar todos los grupos, definirá al azar los equipos en cada grupo.",
  "randomizeAllGroupsDescription":"Esto borrará la selección actual del equipo para los grupos.",
  "friendlyGame":"Amistoso",
  "maxTeamNumberReached":"Número máximo de equipos logrados, elimine un equipo para agregar otro.",
  "qtyChampionship":"Cant. Campeonatos",
  "summary": "Resumen",
  "gameSummary": "Resumen del juego",
  "summarySucccessfullyLoaded":"Resumen cargado exitosamente",
  "competitionGroupIsUnavailable":"El grupo de competición no está disponible.",
  "seeCompetitionGroup":"Ver grupo de competición",
  "seeSummary": "Ver resumen",
  "summaryOccurences": {
    "action": "Acción",
    "playerName" : "Jugador",
    "playerNumber" : "Nr. jugador",
    "playerPosition" : "Pos. jugador",
    "periodTime" : "Período de partido",
    "period" : "Fase",
    "gameHour" : "Tiempo",
    "scoredGoals": "GOLOS MARCADOS",
    "subtitleDescription" : "(Comienza con las ocurrencias más recientes del partido)",
    "faultsAndCards": "FALLOS Y TARJETAS APLICADAS"
  },
  "summaryApprove":{
    "title": "¡Importante!",
    "body1": "¡Al aprobar el resumen, las estadísticas del partido/equipo/jugador y las puntuaciones de los equipos en la competición se vuelven oficialmente válidas en el sistema!",
    "body2": "Una vez que usted (responsable del campeonato) apruebe el resumen, ¡NO SE PUEDE CAMBIAR MÁS!",
    "body3": "Para confirmar esta operación, completa a continuación las conclusiones finales de este partido.",
    "winner": "Ganador",
    "hasOvertime": "Habia tiempo adicional",
    "hasWo": "Habia wo",
    "approove": "Aprobar",
    "approoved": "Aprobada",
    "classifiedTeam":"Equipo clasificado",
    "draw":"Empató",
    "no": "No",
    "yes": "Sí",
    "canceled": "Cancelada",
    "preApprooved": "Pre aprobado"
  },
  "noResultsFound":"No se han encontrado resultados",
  "createTeam":"Crear equipo",
  "teamCreated":"Equipo creado exitosamente",
  "editTeam":"Editar time",
  "teamEdited":"Equipo cambiado exitosamente",
  "areYouSureToFinishChampionship": "¿Estás seguro de que quieres terminar el campeonato: {{CompetitionName}}?",
  "isNotPossibleToEditMatchIfChampioionshipIsFinished": "¡No és permitido cambiar el partido en un campeonato terminado!",
  "matchWasCanceled":"Partido fue cancelado",
  "unsubscribed": "Preinscrito",
  "subscribed": "Registrado",
  "expandTable": "Ampliar tabla",
  "unExpandTable": "Desespandir tabla",
  "expandTableRows": "Ampliar filas de la tabla",
  "unExpandTableRows": "Desexpandir filas de la tabla",
  "see": "Ver",
  "dragTheFileOr": "Arrastre el archivo o",
  "inYourComputer": "En tu computadora",
  "sendMaxFileSize": "Enviar tamaño máximo de archivo",
  "sendFile": "Enviar archivo",
  "editFile": "Editar archivo",
  "colors2": "Colores",
  "noOptions": "No hay resultados...",
  "playerHasMatchOccurrences": "El jugador no puede darse de baja porque ya ha sido seleccionado",
  "tabTechnicalCommittee": "Comité técnico",
  "sendInvites": "Enviar invitaciones",
  "sentInvites": "Invitaciones enviadas",
  "addOnlyEmail": "Agregar solo correo electrónico",
  "searchForANameToPickUpPlayersForSendingTheInvites": "Busque un nombre para recoger jugadores y enviar las invitaciones",
  "send": "Enviar",
  "colors": {
    "violetBlue": "Azul Violeta",
    "brightTurquoiseBlue": "Azul Turquesa Brilhante",
    "turquoiseBlue": "Azul Turquesa",
    "tokyoBlue": "Azul Tóquio",
    "taparuereBlue": "Azul Taparuere",
    "royalBlue": "Azul Royal",
    "gunpowderBlue": "Azul Pólvora",
    "tealBlue": "Azul-Verde",
    "midnightBlue": "Azul Meia-Noite",
    "mediumBlue": "Azul Médio",
    "navyBlue": "Azul Marinho",
    "butterBlue": "Azul Manteiga",
    "stealthBlue": "Azul Stealth",
    "airForceBlue": "Azul Força Aérea",
    "cornflowerBlue": "Azul Centáurea",
    "darkBlue": "Azul Escuro",
    "cobaltBlue": "Azul Cobalto",
    "lightBlue": "Azul Claro",
    "deepSkyBlue": "Azul Céu Profundo",
    "lightSkyBlue": "Azul Céu Claro",
    "skyBlue": "Azul Céu",
    "brightSkyBlue": "Azul Céu Brilhante",
    "celeste": "Celeste",
    "comradeBlue": "Azul Camarada",
    "cadetBlue": "Azul Cadete",
    "brilliantBrazilianBlue": "Azul Brasileiro Brilhante",
    "brazilianBlue": "Azul Brasileiro",
    "sandyBlue": "Azul Areia",
    "mediumSlateBlue": "Azul Ardósia Médio",
    "darkSlateBlue": "Azul Ardósia Escuro",
    "lightSlateBlue": "Azul Ardósia Claro",
    "slateBlue": "Azul Ardósia",
    "aliceBlue": "Azul Alice",
    "lightSteelBlue": "Azul Aço Claro",
    "steelBlue": "Azul Aço",
    "blue": "Azul",
    "asparagus": "Aspargo",
    "amethyst": "Ametista",
    "almond": "Amêndoa",
    "plum": "Ameixa",
    "amber": "Âmbar",
    "burntYellow": "Amarillo Quemado",
    "lightGoldYellow": "Amarillo Oro Claro",
    "greenishYellow": "Amarillo Verdoso",
    "darkYellow": "Amarillo Oscuro",
    "creamYellow": "Amarillo Crema",
    "lightYellow": "Amarillo Claro",
    "brazilianYellow": "Amarillo Brasileño",
    "yellow": "Amarillo",
    "alizarin": "Alizarina",
    "mediumAquamarine": "Aguamarina Media",
    "aquamarine": "Aguamarina",
    "water": "Agua",
    "saffron": "Azafrán",
    "beige": "Beige",
    "burgundy": "Burdeos",
    "white": "Blanco",
    "antiqueWhite": "Blanco Antiguo",
    "ghostWhite": "Blanco Fantasma",
    "floralWhite": "Blanco Floral",
    "smokeWhite": "Blanco Humo",
    "navajoWhite": "Blanco Navajo",
    "brazil": "Brasil",
    "bronze": "Bronce",
    "khaki": "Caqui",
    "thistle": "Cardo",
    "crimson": "Carmesí",
    "carmine": "Carmín",
    "classicCarmine": "Carmín Clásico",
    "carnaceousCrimson": "Carmesí Carnoso",
    "reddishBrown": "Marrón Rojizo",
    "lightBrown": "Marrón Claro",
    "carrot": "Zanahoria",
    "cherry": "Cereza",
    "hollywoodCherry": "Cereza Hollywood",
    "chocolate": "Chocolate",
    "cyan": "Cian",
    "lightCyan": "Cian Claro",
    "darkCyan": "Cian Oscuro",
    "gray": "Gris",
    "slateGray": "Gris Pizarra",
    "lightSlateGray": "Gris Pizarra Claro",
    "darkSlateGray": "Gris Pizarra Oscuro",
    "lightGray": "Gris Claro",
    "darkGray": "Gris Oscuro",
    "dullGray": "Gris Opaco",
    "mediumGray": "Gris Medio",
    "copper": "Cobre",
    "shell": "Concha",
    "coral": "Coral",
    "lightCoral": "Coral Claro",
    "leather": "Cuero",
    "cream": "Crema",
    "creamShellfish": "Crema Mariscos",
    "creamOfMint": "Crema de Menta",
    "darkKhaki": "Caqui Oscuro",
    "dainise": "Dainise",
    "golden": "Dorado",
    "darkGold": "Dorado Oscuro",
    "paleGold": "Dorado Pálido",
    "ebony": "Ébano",
    "eminence": "Eminencia",
    "scarlet": "Escarlata",
    "emerald": "Esmeralda",
    "eucalyptus": "Eucalipto",
    "fandango": "Fandango",
    "feldspar": "Feldespato",
    "rust": "Óxido",
    "flirt": "Coqueteo",
    "soot": "Hollín",
    "fuchsia": "Fucsia",
    "maroon": "Granate",
    "gainsboro": "Gris Azulado",
    "glitter": "Brillo",
    "guava": "Guayaba",
    "herbal": "Herbal",
    "heliotrope": "Heliotropo",
    "indigo": "Índigo",
    "independence": "Independencia",
    "iris": "Iris",
    "jade": "Jade",
    "jambo": "Jambo",
    "jasmine": "Jazmín",
    "kiwi": "Kiwi",
    "kobi": "Kobi",
    "kobicha": "Kobicha",
    "orange": "Naranja",
    "darkOrange": "Naranja Oscuro",
    "lightOrange": "Naranja Claro",
    "lavender": "Lavanda",
    "reddishLavender": "Lavanda Rojiza",
    "lilac": "Lila",
    "lime": "Limón",
    "lemon": "Limón",
    "linen": "Lino",
    "wood": "Madera",
    "magenta": "Magenta",
    "darkMagenta": "Magenta Oscuro",
    "mauve": "Malva",
    "papayaBlended": "Papaya Mezclado",
    "manna": "Maná",
    "ivory": "Marfil",
    "yellowishBrown": "Marrón Amarillento",
    "pinkishBrown": "Marrón Rosado",
    "brownSaddle": "Marrón Silla de Montar",
    "corn": "Maíz",
    "lightCorn": "Maíz Claro",
    "moccasin": "Mocasín",
    "mustard": "Mostaza",
    "navy": "Azul Marino",
    "snow": "Nieve",
    "nyanza": "Nyanza",
    "ochre": "Ocre",
    "olive": "Oliva",
    "darkOlive": "Oliva Oscura",
    "brownOlive": "Oliva Marrón",
    "orchid": "Orquídea",
    "darkOrchid": "Orquídea Oscura",
    "mediumOrchid": "Orquídea Media",
    "gold": "Dorado",
    "brown": "Marrón",
    "darkBrown": "Marrón Oscuro",
    "silver": "Plateado",
    "black": "Negro",
    "peach": "Durazno",
    "purple": "Morado",
    "mediumPurple": "Morado Medio",
    "quantum": "Cuantum",
    "quartz": "Cuarzo",
    "antiqueLace": "Encaje Antiguo",
    "pink": "Rosa",
    "brightPink": "Rosa Brillante",
    "lovePink": "Rosa Amor",
    "lightPink": "Rosa Claro",
    "damnPink": "Rosa Intenso",
    "dullPink": "Rosa Opaco",
    "hotPink": "Rosa Chocante",
    "deepPink": "Rosa Profundo",
    "brazilianPurple": "Morado Brasileño",
    "rutile": "Rutilo",
    "salmon": "Salmón",
    "lightSalmon": "Salmón Claro",
    "darkSalmon": "Salmón Oscuro",
    "siena": "Siena",
    "sepia": "Sepia",
    "tangerine": "Mandarina",
    "terracotta": "Terracota",
    "firebrick": "Ladrillo Refractario",
    "tomato": "Tomate",
    "wheat": "Trigo",
    "triassic": "Triásico",
    "turquoise": "Turquesa",
    "darkTurquoise": "Turquesa Oscura",
    "mediumTurquoise": "Turquesa Media",
    "turquoisePale": "Turquesa Pálida",
    "ube": "Ube",
    "annatto": "Achiote",
    "green": "Verde",
    "spectrumGreen": "Verde Espectral",
    "yellowishGreen": "Verde Amarillento",
    "lightGreen": "Verde Claro",
    "darkGreen": "Verde Oscuro",
    "forestGreen": "Verde Bosque",
    "fluorescentGreen": "Verde Fluorescente",
    "limeGreen": "Verde Lima",
    "grassGreen": "Verde Hierba",
    "lightSeaGreen": "Verde Mar Claro",
    "darkSeaGreen": "Verde Mar Oscuro",
    "mediumSeaGreen": "Verde Mar Medio",
    "militaryGreen": "Verde Militar",
    "oliveGreen": "Verde Oliva",
    "parisGreen": "Verde París",
    "springGreen": "Verde Primavera",
    "mediumSpringGreen": "Verde Primavera Medio",
    "paleGreen": "Verde Pálido",
    "blueGreen": "Verde Azulado",
    "red": "Rojo",
    "blackishRed": "Rojo Ennegrecido",
    "darkRed": "Rojo Oscuro",
    "redIndian": "Rojo Indio",
    "redViolet": "Rojo Violeta",
    "mediumRedViolet": "Rojo Violeta Medio",
    "paleRedViolet": "Rojo Violeta Pálido",
    "violet": "Violeta",
    "darkViolet": "Violeta Oscuro",
    "lightViolet": "Violeta Claro",
    "xanadu": "Xanadú"
  },
  "positions": {
    "goalkeeper": "Portero",
    "winger": "Extremo",
    "pivot": "Pivote",
    "fixed": "Fijo",
    "defender": "Defensor",
    "full-back": "Lateral",
    "defensiveMidfielder": "Volante",
    "midfielder": "Mediocampista",
    "striker": "Delantero",
    "centerForward": "Centro atacante",
    "representative": "Representante",
    "doctor": "Doctor",
    "coach": "Entrenador",
    "physiotherapist": "Fisioterapeuta",
    "performanceAnalyst": "Analista de desempeño",
    "masseur": "Masajista",
    "physicalTrainer": "Entrenador fisico",
    "supervisor": "Supervisor",
    "technicalAssistant": "Asistente técnico",
    "goalkeeperTrainer": "Portero entrenador"
  },
  "editPlayer":"Editar Jugador",
  "showChampionshipId": "Mostrar id del campeonato",
  "showChampionshipIdModalMinorDescription": "NOTA: Si es necesario, contacta con el Equipo Futstat para más información.",
  "showChampionshipIdModalDescription": "Utilice este código de identificación para consultar (vía API) información sobre el campeonato seleccionado:",
  "showChampionshipIdModalTitle": "¿Copiar el código de identificación del campeonato?",
  "copiedCodeSuccessfully": "Codigo copiado con éxito",
  "createGamesTable":"Crear tabla del juegos",
  "refereeSetSuccessfully":"Árbitro definido exitosamente!",
  "update":"Actualizar",
  "clear":"Limpiar",
  "somethingWentWrongWithTheMatchUpdate": "Se produjo un error al actualizar el {{game}}º juego",
  "somethingWentWrong": "Se produjo un error al realizar esta acción."
}
