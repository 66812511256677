import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Avatar,
  Drawer,
  Tooltip,
  Typography,
  CardActionArea,
  useTheme,
  Grid,
  IconButton
} from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import { MHidden } from '../../components/@material-extend';
import { useSelector } from 'react-redux';
import { AuthReducerState } from '../../redux/reducers/authReducer';
import { getFirstCharacter } from '../../utils/firstCharacter';
import { useTranslation } from 'react-i18next';
import TypographStyles from '../../theme/typography';
//import { UserAccessType } from '../../apis/Api';
import AdminSidebarConfig from './AdminSideBarConfig';
import { ThemeReducerState } from '../../redux/reducers/themeReducer';
import { MiscellaneousReducerState } from '../../redux/reducers/miscellaneousReducer';
import { PATH_MANAGER } from '../../routes/paths';
import Link from '../../components/@material-extend/Link';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 300;
const COLLAPSE_WIDTH = 102;
const SPACE_WIDTH = 130;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

export const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 1.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

// type IconCollapseProps = {
//   onToggleCollapse: VoidFunction;
//   collapseClick: boolean;
// };

// function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
//   return (
//     // <Tooltip title="Mini Menu">
//       <CardActionArea
//         onClick={onToggleCollapse}
//         sx={{
//           width: 18,
//           height: 18,
//           display: 'flex',
//           cursor: 'pointer',
//           borderRadius: '50%',
//           alignItems: 'center',
//           color: 'text.primary',
//           justifyContent: 'center',
//           border: 'solid 1px currentColor',
//           ...(collapseClick && {
//             borderWidth: 2
//           })
//         }}
//       >
//         <Box
//           sx={{
//             width: 8,
//             height: 8,
//             borderRadius: '50%',
//             bgcolor: 'currentColor',
//             transition: (theme) => theme.transitions.create('all'),
//             ...(collapseClick && {
//               width: 0,
//               height: 0
//             })
//           }}
//         />
//       </CardActionArea>
//     // </Tooltip>
//   );
// }

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const {t} = useTranslation()
  const { login, name } = useSelector((state: { auth: AuthReducerState }) => state?.auth);
  const misc = useSelector((state: { miscellaneous: MiscellaneousReducerState }) => state?.miscellaneous);
  // const { selectedTheme } = useSelector((state: { theme: ThemeReducerState }) => state?.theme)
  const theme  = useTheme()

  
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer();

  const [showMiniIcon, setShowMiniIcon] = useState<boolean>(window.innerWidth > 1000);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);



  window.addEventListener('resize', (e) => {
    const {target} = e;
    if(target.innerWidth < 1000){
      setShowMiniIcon(false)
    }
    else{
      setShowMiniIcon(true)
    }
  });



  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >

      <Stack
        spacing={1}
        sx={{
          px: 2.5,
          pt: 1,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >

        {
          showMiniIcon && (
            <Grid>
              {isCollapse ? (
                <IconButton onClick={onToggleCollapse}>
                  <FormatListBulletedIcon />
                </IconButton>
              ): (
                <IconButton onClick={onToggleCollapse} 
                  style={{position: 'relative', marginLeft: 230, zIndex: 9999, color: theme.palette.grey[600]}}

                  >
                  <ChevronLeftIcon />
                </IconButton>
              )}
            </Grid>  
          )
        }


        <Stack sx={{mt: 10}} direction="row" alignItems="center" justifyContent="start">
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex', ml: isCollapse ? 0 : 6 , mb: isCollapse ? 0 : 2, mt: isCollapse ? 0 : 6 }}>
            <Logo type='single' size='small' />
          </Box>
        </Stack>

        {isCollapse ? (
          <Grid sx={{ pb: 3, pt: 3 }}>
            <Avatar>{getFirstCharacter(login ?? '')}</Avatar>          
          </Grid>
        ) : (
          <>


            <Link component={RouterLink} to={PATH_MANAGER.settings.user}>
              <AccountStyle sx={{ mt: 6, mb: 1 }}>
                {
                  (
                    <Avatar>{getFirstCharacter(login ?? '')}</Avatar>
                  )
                }
                <Box sx={{ ml: 1}}>
                  <Typography variant="subtitle2" noWrap sx={{ color: 'text.primary', }}>
                    {login?.length! >= 26 ? `${login?.substring(0, 26)}...` : login}
                  </Typography>
                </Box>
              </AccountStyle>
            </Link>
            
            {/* {(misc.selectedCompetitionGroup?.id ?? 0) > 0 
            ? (
              <Box sx={{mb: 0, mt: 0, pt: 5, pb: 0}}>
                <Typography variant='subtitle1'>{t('competitionManager:selectedCompetition')}:</Typography>
                <Typography fontWeight={700} variant='subtitle1' ml={3} mb={3} mt={2}>
                  <ul>
                    <li>
                      <span>
                        {misc.selectedCompetitionGroup?.name} 
                        <Link component={RouterLink} to={PATH_MANAGER.competitionGroups.root}> ({t('competitionManager:change')})</Link>
                      </span>
                    </li>
                  </ul>
                </Typography>
              </Box>)
            : null}             */}
        </>
        )}
      </Stack>

      <NavSection navConfig={AdminSidebarConfig()} isShow={!isCollapse} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          // onMouseEnter={onHoverEnter}
          // onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
