export enum Type {  
  PontosCorridos = "Pontos corridos",
  PontosCorridosMataMata = "Pontos corridos mata-mata",
  Groups = "Grupos",
  AmistososConfrontDireto = "Amistosos - Confrontos Direto",
  NaoSeAplica = "Não se aplica"
}

export enum enrolPeriod {
  Aberto = "Aberto",
  Fechado = "Fechado"
}

export enum ManagementMode {
  System = "System",
  Manual = "Manual"
}

export enum YesOrNo {
  Yes = "yes",
  No = "no"
}

export enum Status {
  EmConstrucao = "Em construção",
  Iniciado = "Iniciado",
  Finalizado = "Finalizado"
}