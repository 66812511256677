// routes
import { PATH_MANAGER } from '../../routes/paths';

// components
// import SvgIconStyle from '../../components/SvgIconStyle';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { MiscellaneousReducerState } from '../../redux/reducers/miscellaneousReducer';
import { useTranslation } from 'react-i18next';
import { AuthReducerState } from '../../redux/reducers/authReducer';
import { AccessRoleType } from '../../apis/data-contracts';
// ----------------------------------------------------------------------

// const getIcon = (name: string) => (
//   <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
// );


export default function AdminSidebarConfig() { 
  const { t } = useTranslation();
  const misc = useSelector((state: { miscellaneous: MiscellaneousReducerState }) => state?.miscellaneous);
  const { roles } = useSelector((state: { auth: AuthReducerState }) => state?.auth);

  if(roles?.includes(AccessRoleType.SysAdmin) || roles?.includes(AccessRoleType.CompetitionManager)){
    let configs = [{
      subheader:  misc?.selectedCompetitionGroup?.name ?? '',
      itemPath: PATH_MANAGER.competition.list,
      items: [
        {
          title: t('competitionManager:competitions'),
          path: PATH_MANAGER.competitionGroups.root,
          icon: <Icon icon={'mdi:tournament'} style={{ fontSize: '25px' }}/>
        }
      ]
    }];

    if((misc.selectedCompetition?.id ?? 0) > 0) {
      configs = configs.concat({
        subheader: misc?.selectedCompetition?.name ?? '',
        itemPath: PATH_MANAGER.competition.team.list,
        items: [
          {
            title: t('competitionManager:championships'),
            path: PATH_MANAGER.competition.list,
            icon: <Icon icon="bi:trophy" style={{ fontSize: '25px' }} />
          }
        ]});
      }

      if((misc.selectedTeam?.id ?? 0) > 0){
        configs = configs.concat({
          subheader: misc?.selectedTeam?.name ?? '',
          itemPath: PATH_MANAGER.competition.team.player.list,
          items: [
            {
              title: t('competitionManager:teams'),
              path: PATH_MANAGER.competition.team.list,
              icon: <Icon icon="ri:shield-line" style={{ fontSize: '25px' }} />
            }
          ]});
        }

      if((misc.selectedPlayer?.playerId ?? 0) > 0){
        configs = configs.concat({
          subheader: misc?.selectedPlayer?.nickname ?? '',
          itemPath: PATH_MANAGER.competition.team.player.edit,
          items: [
            {
              title: t('competitionManager:fields:player'),
              path: PATH_MANAGER.competition.team.player.edit,
              icon: <Icon icon="carbon:soccer" style={{ fontSize: '25px' }} />
            }
          ]});
        }

        if((misc.selectedMatch?.matchId ?? 0) > 0){
          configs = configs.concat({
            subheader: `${misc?.selectedMatch?.teamHomeName} x ${misc?.selectedMatch?.teamAwayName}`,
            itemPath: PATH_MANAGER.competition.match.summary,
            items: [
              {            
                title: t('competitionManager:match'),
                path: PATH_MANAGER.competition.match.list,
                icon: <Icon icon="fluent-emoji-high-contrast:soccer-ball" style={{ fontSize: '25px' }} />
              }
            ]});
          }
      return configs;
  }

  if(!roles?.includes(AccessRoleType.SysAdmin) && !roles?.includes(AccessRoleType.CompetitionManager) && roles?.includes(AccessRoleType.TeamManager))  {
    let configs = [{
      // subheader: 'Times',
      itemPath: PATH_MANAGER.competition.team.list,
      items: [
        {
          title: t('competitionManager:teams'),
          path: PATH_MANAGER.competition.team.list,
          icon: <Icon icon="ri:shield-line" style={{ fontSize: '25px' }} />
        }
      ]
      }];

      if((misc.selectedTeam?.id ?? 0) > 0){
        configs = configs.concat({
        //subheader: 'asdasdasd',
        itemPath: PATH_MANAGER.competition.team.player.list,
        items: [
          {
            title: misc?.selectedTeam?.name ?? '',
            path: PATH_MANAGER.competition.team.player.list,
            icon: <Icon icon="fluent:people-team-20-filled" style={{ fontSize: '25px' }} />
          }
        ]});
      }
      if((misc.selectedPlayer?.playerId ?? 0) > 0){
        configs = configs.concat({
          subheader: misc?.selectedPlayer?.nickname ?? '',
          itemPath: PATH_MANAGER.competition.team.player.edit,
          items: [
            {
              title: t('competitionManager:fields:player'),
              path: PATH_MANAGER.competition.team.player.edit,
              icon: <Icon icon="carbon:soccer" style={{ fontSize: '25px' }} />
            }
          ]});
        }

      return configs;
    }
}
